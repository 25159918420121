/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any
  /**
   * Metadata is a map of key-value pairs, both keys and values are `String`.
   *
   * Example:
   * ```
   * {
   *     "key1": "value1",
   *     "key2": "value2"
   * }
   * ```
   */
  Metadata: any
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any
}

/**
 * Accept frame contract.
 *
 * Requires one of the following permissions: AUTHENTICATED_USER.
 */
export type AcceptInvitation = {
  __typename: 'AcceptInvitation'
  errors: Array<Error>
}

/** An enumeration. */
export enum AccountType {
  CAFE = 'CAFE',
  COMPANY = 'COMPANY',
  DISTRIBUTOR = 'DISTRIBUTOR',
  INDIVIDUAL = 'INDIVIDUAL',
  ROASTERY = 'ROASTERY',
}

export type Activity = {
  __typename: 'Activity'
  actor: Maybe<ContactInformation>
  createdAt: Scalars['DateTime']
  /** Customer specific metadata. All this values could be displayed to the customer and vendor. */
  customerMetadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  customerMetafield: Maybe<Scalars['Metadata']>
  event: ActivityEvent
  id: Scalars['ID']
  /** Merged customer and vendor using priority. Vendor metadata values have more priority. This metadata should be displayed to the vendor only. */
  mergedMetadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from merged vendor's and customer's metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  mergedMetafield: Maybe<Scalars['Metadata']>
  updatedAt: Scalars['DateTime']
  /** Vendor specific metadata. This metadata should be displayed to the vendor only. */
  vendorMetadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  vendorMetafield: Maybe<Scalars['Metadata']>
}

export type ActivityCustomerMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type ActivityCustomerMetafieldArgs = {
  key: Scalars['String']
}

export type ActivityMergedMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type ActivityMergedMetafieldArgs = {
  key: Scalars['String']
}

export type ActivityVendorMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type ActivityVendorMetafieldArgs = {
  key: Scalars['String']
}

/** An enumeration. */
export enum ActivityEvent {
  DRAFT_ORDER_CREATED = 'DRAFT_ORDER_CREATED',
  INVOICE_UPLOADED = 'INVOICE_UPLOADED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_PAID = 'ORDER_PAID',
  PAYMENT_CANCELED = 'PAYMENT_CANCELED',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
}

/**
 * Add item to the draft order.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type AddOrderItem = {
  __typename: 'AddOrderItem'
  errors: Array<OrderError>
  order: Maybe<Order>
}

export type AddOrderItemInput = {
  /** Product id that is required to add in the draft order. */
  productId: Scalars['ID']
  /** Count of items that is required to add in the draft order. */
  quantity: Scalars['Int']
}

/** Represents type of Value that chosen Attribute can or must have. */
export enum AttributeType {
  BOOL = 'BOOL',
  CHOICE = 'CHOICE',
  DATE = 'DATE',
  FILES = 'FILES',
  FLOAT = 'FLOAT',
  INT = 'INT',
  MULTI_CHOICE = 'MULTI_CHOICE',
  MULTI_REFERENCE = 'MULTI_REFERENCE',
  REFERENCE = 'REFERENCE',
  TEXT = 'TEXT',
}

/** An enumeration. */
export enum AvailabilityType {
  AVAILABLE_NOW = 'AVAILABLE_NOW',
  AVAILABLE_ON_DEMAND = 'AVAILABLE_ON_DEMAND',
}

/** Contains configuration of an integration with Baselinker. */
export type BaselinkerConfiguration = {
  __typename: 'BaselinkerConfiguration'
  apiKey: Scalars['String']
  id: Scalars['ID']
  integration: Integration
  newOrderStatusId: Scalars['Int']
}

export type BaselinkerConfigurationUpdateInput = {
  apiKey: Scalars['String']
  newOrderStatusId: Scalars['Int']
}

export type BillingAddress = {
  __typename: 'BillingAddress'
  city: Scalars['String']
  companyName: Maybe<Scalars['String']>
  country: Scalars['String']
  houseNumber: Scalars['String']
  name: Scalars['String']
  street: Scalars['String']
  taxId: Maybe<Scalars['String']>
  zipCode: Scalars['String']
}

/** An enumeration. */
export enum BrewingProfile {
  ESPRESSO = 'ESPRESSO',
  FILTER = 'FILTER',
  OMNIROAST = 'OMNIROAST',
}

export type Category = {
  __typename: 'Category'
  icon: Maybe<CategoryIcon>
  id: Scalars['ID']
  name: Scalars['String']
  path: Scalars['String']
  slug: Scalars['String']
  subcategories: Array<Category>
}

export type CategoryCountableConnection = {
  __typename: 'CategoryCountableConnection'
  edges: Array<CategoryCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type CategoryCountableEdge = {
  __typename: 'CategoryCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Category
}

export type CategoryFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

/** An enumeration. */
export enum CategoryIcon {
  BARISTA_TOOL = 'BARISTA_TOOL',
  BOOK = 'BOOK',
  BOTTLE = 'BOTTLE',
  BREWER = 'BREWER',
  CLEANING = 'CLEANING',
  COFFEE = 'COFFEE',
  CROCKERY = 'CROCKERY',
  FILTER = 'FILTER',
  GRINDER = 'GRINDER',
  HORECA = 'HORECA',
  MACHINE = 'MACHINE',
  OTHER = 'OTHER',
  TEA = 'TEA',
}

export enum CategorySortField {
  /** Sort categories by name. */
  NAME = 'NAME',
}

export type CategorySortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort categories by the selected field. */
  field: CategorySortField
}

/** An enumeration. */
export enum CoffeeFormat {
  CAPSULES = 'CAPSULES',
  GROUND = 'GROUND',
  PODS = 'PODS',
  WHOLE_BEAN = 'WHOLE_BEAN',
}

/** Contains structured information about coffee products. */
export type CoffeeInfo = {
  __typename: 'CoffeeInfo'
  brewingProfile: Maybe<BrewingProfile>
  format: Maybe<CoffeeFormat>
  origins: Array<CoffeeOriginInfo>
  roastProfile: Maybe<RoastProfile>
}

/** Contains structured information about coffee origin information. Multiple origins indicate that coffee is a blend. */
export type CoffeeOriginInfo = {
  __typename: 'CoffeeOriginInfo'
  country: Maybe<Country>
  processingType: Maybe<ProcessingType>
}

/** An enumeration. */
export enum CommonErrorCodes {
  DOES_NOT_EXIST = 'DOES_NOT_EXIST',
  DUPLICATE = 'DUPLICATE',
  INVALID = 'INVALID',
  REQUIRED = 'REQUIRED',
}

export type ContactInformation = {
  __typename: 'ContactInformation'
  email: Scalars['String']
  firstName: Scalars['String']
  fullName: Scalars['String']
  lastName: Scalars['String']
  phone: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum Country {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

/**
 * Create a new discount group
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type CreateDiscountGroup = {
  __typename: 'CreateDiscountGroup'
  discountGroup: Maybe<DiscountGroup>
  errors: Array<Error>
}

/**
 * Create new empty draft order.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type CreateDraftOrder = {
  __typename: 'CreateDraftOrder'
  errors: Array<OrderError>
  order: Maybe<Order>
}

/**
 * Create a new flavour profile
 *
 * Requires one of the following permissions: MANAGE_FLAVOUR_PROFILES.
 */
export type CreateFlavourProfile = {
  __typename: 'CreateFlavourProfile'
  errors: Array<Error>
  flavourProfile: Maybe<FlavourProfile>
}

/**
 * Create a new inventory item
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type CreateInventoryItem = {
  __typename: 'CreateInventoryItem'
  errors: Array<Error>
  inventoryItem: Maybe<InventoryItem>
}

/**
 * Create a new inventory location
 *
 * Requires one of the following permissions: MANAGE_INVENTORY_LOCATIONS.
 */
export type CreateInventoryLocation = {
  __typename: 'CreateInventoryLocation'
  errors: Array<Error>
  inventoryLocation: Maybe<InventoryLocation>
}

/**
 * Create a new private product group
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type CreatePrivateProductGroup = {
  __typename: 'CreatePrivateProductGroup'
  errors: Array<Error>
  privateProductGroup: Maybe<PrivateProductGroup>
}

export type CreatePrivateProductGroupInput = {
  customerIds: Array<Scalars['ID']>
  inventoryItemIds: Array<Scalars['ID']>
  name: Scalars['String']
}

/**
 * Creates new product draft, initial product draft variant and inventory item for this variant.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type CreateProductDraft = {
  __typename: 'CreateProductDraft'
  errors: Array<Error>
  productDraft: Maybe<ProductDraft>
}

/**
 * Creates new product draft variant and inventory item for this variant.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type CreateProductDraftVariant = {
  __typename: 'CreateProductDraftVariant'
  errors: Array<Error>
  productDraftVariant: Maybe<ProductDraftVariant>
}

/**
 * Create a new tax group
 *
 * Requires one of the following permissions: MANAGE_TAX_GROUPS.
 */
export type CreateTaxGroup = {
  __typename: 'CreateTaxGroup'
  errors: Array<Error>
  taxGroup: Maybe<TaxGroup>
}

/** Contains configuration of an integration with Cropster. */
export type CropsterConfiguration = {
  __typename: 'CropsterConfiguration'
  apiKey: Scalars['String']
  apiSecret: Scalars['String']
  id: Scalars['ID']
  integration: Integration
  orderableProducts: Array<CropsterOrderableProduct>
}

export type CropsterConfigurationUpdateInput = {
  apiKey: Scalars['String']
  apiSecret: Scalars['String']
}

export type CropsterOrderableProduct = {
  __typename: 'CropsterOrderableProduct'
  id: Scalars['String']
  name: Scalars['String']
}

/** An enumeration. */
export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP',
  NOK = 'NOK',
  PLN = 'PLN',
  USD = 'USD',
}

export type CustomPageImage = {
  __typename: 'CustomPageImage'
  customIdentifier: Maybe<Scalars['String']>
  image: Image
  imagePurpose: ImagePurpose
}

export type CustomPageText = {
  __typename: 'CustomPageText'
  customIdentifier: Maybe<Scalars['String']>
  text: Scalars['String']
}

export type Customer = {
  __typename: 'Customer'
  createdAt: Scalars['DateTime']
  discountGroup: Maybe<DiscountGroup>
  id: Scalars['ID']
  invite: Maybe<CustomerInvite>
  lastOrderTime: Maybe<Scalars['DateTime']>
  name: Maybe<Scalars['String']>
  ordersSum: Money
  status: FrameContractStatus
  store: Store
  team: Maybe<Team>
  user: Maybe<ContactInformation>
}

export type CustomerCountableConnection = {
  __typename: 'CustomerCountableConnection'
  edges: Array<CustomerCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
  /** Include total invitation count per each status. */
  totalPerStatus: Maybe<Scalars['GenericScalar']>
}

export type CustomerCountableConnectionTotalPerStatusArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type CustomerCountableEdge = {
  __typename: 'CustomerCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Customer
}

export type CustomerError = {
  __typename: 'CustomerError'
  code: FrameContractErrorCodes
  field: Scalars['String']
  message: Maybe<Scalars['String']>
}

export type CustomerFilterInput = {
  discountGroups?: InputMaybe<Array<Scalars['ID']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Array<FrameContractStatus>>
}

export type CustomerInvite = {
  __typename: 'CustomerInvite'
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phone: Maybe<Scalars['String']>
}

export type CustomerOrderFilterInput = {
  createdAt?: InputMaybe<DateTimeRangeInput>
  deliveryDate?: InputMaybe<DateRangeInput>
  fulfillmentStatus?: InputMaybe<Array<FulfillmentStatus>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  paymentStatus?: InputMaybe<Array<OrderPaymentStatus>>
  search?: InputMaybe<OrderSearch>
  status?: InputMaybe<Array<OrderStatus>>
}

export enum CustomerSortField {
  /** Sort customers by creation date. */
  CREATED = 'CREATED',
}

export type CustomerSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort customers by the selected field. */
  field: CustomerSortField
}

export type DateRangeInput = {
  /** Start date. */
  gte?: InputMaybe<Scalars['Date']>
  /** End date. */
  lte?: InputMaybe<Scalars['Date']>
}

export type DateTimeRangeInput = {
  /** Start datetime. */
  gte?: InputMaybe<Scalars['DateTime']>
  /** End datetime. */
  lte?: InputMaybe<Scalars['DateTime']>
}

/**
 * Deletes a discount group.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type DeleteDiscountGroup = {
  __typename: 'DeleteDiscountGroup'
  discountGroup: Maybe<DiscountGroup>
  errors: Array<Error>
}

/**
 * Deletes an inventory item.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type DeleteInventoryItem = {
  __typename: 'DeleteInventoryItem'
  errors: Array<Error>
  inventoryItem: Maybe<InventoryItem>
}

/**
 * Deletes an inventory location.
 *
 * Requires one of the following permissions: MANAGE_INVENTORY_LOCATIONS.
 */
export type DeleteInventoryLocation = {
  __typename: 'DeleteInventoryLocation'
  errors: Array<Error>
  inventoryLocation: Maybe<InventoryLocation>
}

/**
 * Delete multiple private product groups
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type DeletePrivateProductGroups = {
  __typename: 'DeletePrivateProductGroups'
  errors: Array<Error>
}

/**
 * Delete logo for store
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type DeleteStoreLogo = {
  __typename: 'DeleteStoreLogo'
  errors: Array<Error>
}

/**
 * Deletes a tax group.
 *
 * Requires one of the following permissions: MANAGE_TAX_GROUPS.
 */
export type DeleteTaxGroup = {
  __typename: 'DeleteTaxGroup'
  errors: Array<Error>
  taxGroup: Maybe<TaxGroup>
}

/** Contains information about a discount group defined by the vendor. */
export type DiscountGroup = {
  __typename: 'DiscountGroup'
  id: Scalars['ID']
  name: Scalars['String']
  store: Store
}

export type DiscountGroupCountableConnection = {
  __typename: 'DiscountGroupCountableConnection'
  edges: Array<DiscountGroupCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type DiscountGroupCountableEdge = {
  __typename: 'DiscountGroupCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: DiscountGroup
}

export type DiscountGroupFilterInput = {
  search?: InputMaybe<Scalars['String']>
}

export type DiscountGroupInput = {
  name: Scalars['String']
}

export type DraftOrderUpdateInput = {
  /** Draft order billing address id. */
  billingAddressId?: InputMaybe<Scalars['ID']>
  /** Draft order shipping address id. */
  shippingAddressId?: InputMaybe<Scalars['ID']>
  /** Draft order shipping method. */
  shippingMethod?: InputMaybe<ShippingMethod>
  /** Custom draft order title. */
  title?: InputMaybe<Scalars['String']>
  /** User, who will be a buyer of newly created order. */
  userId?: InputMaybe<Scalars['ID']>
}

export type Error = {
  __typename: 'Error'
  code: CommonErrorCodes
  field: Scalars['String']
  message: Maybe<Scalars['String']>
}

export type File = {
  /** Path to the file */
  url: Scalars['String']
}

/** Contains information about a flavour profile of coffee. */
export type FlavourProfile = {
  __typename: 'FlavourProfile'
  id: Scalars['ID']
  image: Scalars['String']
  name: Scalars['String']
  parent: Maybe<FlavourProfile>
}

export type FlavourProfileCountableConnection = {
  __typename: 'FlavourProfileCountableConnection'
  edges: Array<FlavourProfileCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type FlavourProfileCountableEdge = {
  __typename: 'FlavourProfileCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: FlavourProfile
}

export type FlavourProfileFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export type FlavourProfileInput = {
  language: Language
  name: Scalars['String']
  parentId?: InputMaybe<Scalars['ID']>
}

export enum FlavourProfileSortField {
  /** Sort flavour profiles by name. */
  NAME = 'NAME',
}

export type FlavourProfileSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort flavour profiles by the selected field. */
  field: FlavourProfileSortField
}

/** An enumeration. */
export enum FrameContractErrorCodes {
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
}

/** An enumeration. */
export enum FrameContractStatus {
  INVITED = 'INVITED',
  REGISTERED = 'REGISTERED',
}

/** An enumeration. */
export enum FulfillmentStatus {
  DELIVERED = 'DELIVERED',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  SENT = 'SENT',
  WAITING = 'WAITING',
}

/** Represents fulfillment time for inventory location. Fulfillment time is a time when the order will leave the warehouse. */
export type FulfillmentTime = {
  __typename: 'FulfillmentTime'
  fulfillmentTimeType: FulfillmentTimeType
  id: Scalars['ID']
  /** Value represents day of the week in the integer format or number of days. This difference comes from `fulfillment_time_type`. If `fulfillment_time_type` is `EXACT_DAY`,value will be week day (0 - Monday; ...; 6 - Sunday). If `fulfillment_time_type` is `DAY_INTERVAL`, value will be number of days. */
  value: Scalars['Int']
}

export type FulfillmentTimeInput = {
  fulfillmentTimeType: FulfillmentTimeType
  /** Small integer value. */
  value: Scalars['Int']
}

/**
 *
 *     :cvar EXACT_DAY:
 *     represents exact day of the week when order will be prepared in presented
 *     location. If `exact_day` is chosen possible value will be limited [0,6] due
 *     to mapping of the week days to their integer representation.
 *     :cvar DAY_INTERVAL:
 *     represents count of days required to prepare the order.
 *
 */
export enum FulfillmentTimeType {
  DAY_INTERVAL = 'DAY_INTERVAL',
  EXACT_DAY = 'EXACT_DAY',
}

export type Image = File & {
  __typename: 'Image'
  /** Path to the file */
  url: Scalars['String']
}

/** An enumeration. */
export enum ImagePurpose {
  BACKGROUND = 'BACKGROUND',
  CENTER = 'CENTER',
  DISPLAY_LEFT = 'DISPLAY_LEFT',
  DISPLAY_RIGHT = 'DISPLAY_RIGHT',
  FOOTER = 'FOOTER',
  LOGO = 'LOGO',
}

/** Contains information about a configured integration instance. */
export type Integration = {
  __typename: 'Integration'
  active: Scalars['Boolean']
  code: IntegrationCode
  id: Scalars['ID']
}

/** An enumeration. */
export enum IntegrationCode {
  BASELINKER = 'BASELINKER',
  CROPSTER = 'CROPSTER',
  QUICKBOOKS = 'QUICKBOOKS',
  SALESFORCE = 'SALESFORCE',
  SENDCLOUD = 'SENDCLOUD',
  SHIPSTATION = 'SHIPSTATION',
  SHOPIFY = 'SHOPIFY',
  SUBIEKT = 'SUBIEKT',
}

/** Contains information about a product that is specific to the vendor. */
export type InventoryItem = {
  __typename: 'InventoryItem'
  basePrice: Money
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  integrationMeta: Array<Maybe<InventoryItemIntegrationMeta>>
  offer: Maybe<ProductOffer>
  price: Maybe<TaxedMoney>
  product: Product
  productDraftVariant: Maybe<ProductDraftVariant>
  quantity: Scalars['Int']
  /** Product Stock Keeping Unit */
  sku: Scalars['String']
  taxGroup: Maybe<TaxGroup>
  updatedAt: Scalars['DateTime']
}

/** Contains information about a product that is specific to the vendor. */
export type InventoryItemIntegrationMetaArgs = {
  integrations?: InputMaybe<Array<InputMaybe<IntegrationCode>>>
}

/** Contains information about a product that is specific to the vendor. */
export type InventoryItemOfferArgs = {
  frameContractId: Scalars['ID']
}

/** Contains information about a product that is specific to the vendor. */
export type InventoryItemProductArgs = {
  language: Language
}

/** Contains information about a product that is specific to the vendor. */
export type InventoryItemProductDraftVariantArgs = {
  language: Language
}

/** Contains information about a product that is specific to the vendor. */
export type InventoryItemQuantityArgs = {
  locationId: Scalars['ID']
}

export type InventoryItemCountableConnection = {
  __typename: 'InventoryItemCountableConnection'
  edges: Array<InventoryItemCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type InventoryItemCountableEdge = {
  __typename: 'InventoryItemCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: InventoryItem
}

export type InventoryItemCreateInput = {
  price: MoneyInput
  productDraftVariantId: Scalars['ID']
  sku: Scalars['String']
  taxGroupId?: InputMaybe<Scalars['ID']>
}

export type InventoryItemFilterInput = {
  categorySlugs?: InputMaybe<Array<Scalars['String']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export type InventoryItemInput = {
  price: MoneyInput
  /** Product Stock Keeping Unit */
  sku: Scalars['String']
  taxGroupId?: InputMaybe<Scalars['ID']>
}

export type InventoryItemIntegrationMeta = {
  __typename: 'InventoryItemIntegrationMeta'
  integration: Integration
  /**
   * Public metadata. Use `keys` to control which fields you want to include. The default is to include everything.
   *
   *
   */
  metadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  metafield: Maybe<Scalars['Metadata']>
}

export type InventoryItemIntegrationMetaMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type InventoryItemIntegrationMetaMetafieldArgs = {
  key: Scalars['String']
}

export enum InventoryItemSortField {
  /** Sort inventory items by name. */
  NAME = 'NAME',
}

export type InventoryItemSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort inventory items by the selected field. */
  field: InventoryItemSortField
}

/** Represents inventory location such as a physical or virtual warehouse. */
export type InventoryLocation = {
  __typename: 'InventoryLocation'
  availabilityType: AvailabilityType
  code: Scalars['String']
  createdAt: Scalars['DateTime']
  fulfillmentTime: Array<FulfillmentTime>
  id: Scalars['ID']
  name: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type InventoryLocationCountableConnection = {
  __typename: 'InventoryLocationCountableConnection'
  edges: Array<InventoryLocationCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type InventoryLocationCountableEdge = {
  __typename: 'InventoryLocationCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: InventoryLocation
}

export type InventoryLocationFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export type InventoryLocationInput = {
  /** Inventory location availability */
  availabilityType: AvailabilityType
  /** Inventory location code */
  code: Scalars['String']
  /** Inventory location fulfillment time. */
  fulfillmentTime?: InputMaybe<Array<FulfillmentTimeInput>>
  /** Inventory location name */
  name: Scalars['String']
}

export enum InventoryLocationSortField {
  /** Sort inventory items by availability. */
  AVAILABILITY = 'AVAILABILITY',
  /** Sort inventory items by code. */
  CODE = 'CODE',
  /** Sort inventory items by created time. */
  CREATED = 'CREATED',
  /** Sort inventory items by name. */
  NAME = 'NAME',
  /** Sort inventory items by updated time. */
  UPDATED = 'UPDATED',
}

export type InventoryLocationSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort inventory locations by the selected field. */
  field: InventoryLocationSortField
}

/** Contains information about an invoice. */
export type Invoice = {
  __typename: 'Invoice'
  createdAt: Scalars['DateTime']
  effectiveDate: Maybe<Scalars['Date']>
  id: Scalars['ID']
  number: Scalars['String']
  pdfFile: Pdf
  total: Maybe<Money>
}

/** An enumeration. */
export enum Language {
  EN = 'EN',
  PL = 'PL',
}

/** Contains information about a manufacturer. */
export type Manufacturer = {
  __typename: 'Manufacturer'
  id: Scalars['ID']
  name: Scalars['String']
}

export type ManufacturerCountableConnection = {
  __typename: 'ManufacturerCountableConnection'
  edges: Array<ManufacturerCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type ManufacturerCountableEdge = {
  __typename: 'ManufacturerCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Manufacturer
}

export type ManufacturerFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  isManufacturer?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
}

export enum ManufacturerSortField {
  /** Sort manufacturers by name. */
  NAME = 'NAME',
}

export type ManufacturerSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort manufacturers by the selected field. */
  field: ManufacturerSortField
}

export type Media = File & {
  __typename: 'Media'
  mediaType: MediaType
  /** Path to the file */
  url: Scalars['String']
}

export enum MediaType {
  IMAGE = 'IMAGE',
}

export type Money = {
  __typename: 'Money'
  amount: Scalars['Decimal']
  currency: Maybe<Currency>
}

export type MoneyInput = {
  amount?: InputMaybe<Scalars['Decimal']>
  currency?: InputMaybe<Currency>
}

export type Mutation = {
  __typename: 'Mutation'
  /**
   * Accept frame contract.
   *
   * Requires one of the following permissions: AUTHENTICATED_USER.
   */
  acceptInvitation: Maybe<AcceptInvitation>
  /**
   * Add item to the draft order.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  addOrderItems: Maybe<AddOrderItem>
  /**
   * Create a new discount group
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  createDiscountGroup: Maybe<CreateDiscountGroup>
  /**
   * Create new empty draft order.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  createDraftOrder: Maybe<CreateDraftOrder>
  /**
   * Create a new flavour profile
   *
   * Requires one of the following permissions: MANAGE_FLAVOUR_PROFILES.
   */
  createFlavourProfile: Maybe<CreateFlavourProfile>
  /**
   * Create a new inventory item
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  createInventoryItem: Maybe<CreateInventoryItem>
  /**
   * Create a new inventory location
   *
   * Requires one of the following permissions: MANAGE_INVENTORY_LOCATIONS.
   */
  createInventoryLocation: Maybe<CreateInventoryLocation>
  /**
   * Create a new private product group
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  createPrivateProductGroup: Maybe<CreatePrivateProductGroup>
  /**
   * Creates new product draft, initial product draft variant and inventory item for this variant.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  createProductDraft: Maybe<CreateProductDraft>
  /**
   * Creates new product draft variant and inventory item for this variant.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  createProductDraftVariant: Maybe<CreateProductDraftVariant>
  /**
   * Create a new tax group
   *
   * Requires one of the following permissions: MANAGE_TAX_GROUPS.
   */
  createTaxGroup: Maybe<CreateTaxGroup>
  /**
   * Deletes a discount group.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  deleteDiscountGroup: Maybe<DeleteDiscountGroup>
  /**
   * Deletes an inventory item.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  deleteInventoryItem: Maybe<DeleteInventoryItem>
  /**
   * Deletes an inventory location.
   *
   * Requires one of the following permissions: MANAGE_INVENTORY_LOCATIONS.
   */
  deleteInventoryLocation: Maybe<DeleteInventoryLocation>
  /**
   * Delete multiple private product groups
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  deletePrivateProductGroups: Maybe<DeletePrivateProductGroups>
  /**
   * Delete logo for store
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  deleteStoreLogo: Maybe<DeleteStoreLogo>
  /**
   * Deletes a tax group.
   *
   * Requires one of the following permissions: MANAGE_TAX_GROUPS.
   */
  deleteTaxGroup: Maybe<DeleteTaxGroup>
  /**
   * Send invitation to an existing customer that haven't registered on the platform yet.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  sendCustomerInvitation: Maybe<SendCustomerInvitation>
  /**
   * Enable/disable integration. If the integration is not configured, trying to enable/disable it will return an error.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  toggleIntegration: Maybe<ToggleIntegration>
  /**
   * Update Baselinker configuration.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateBaselinkerConfiguration: Maybe<UpdateBaselinkerConfiguration>
  /**
   * Update Cropster configuration.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateCropsterConfiguration: Maybe<UpdateCropsterConfiguration>
  /**
   * Update a discount group
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateDiscountGroup: Maybe<UpdateDiscountGroup>
  /**
   * Update draft order.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateDraftOrder: Maybe<UpdateDraftOrder>
  /**
   * Update a flavour profile
   *
   * Requires one of the following permissions: MANAGE_FLAVOUR_PROFILES.
   */
  updateFlavourProfile: Maybe<UpdateFlavourProfile>
  /**
   * Update an inventory item
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateInventoryItem: Maybe<UpdateInventoryItem>
  /**
   * Update Inventory Item metadata for a specific integration.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateInventoryItemIntegrationMeta: Maybe<UpdateInventoryItemIntegrationMetadata>
  /**
   * Update an inventory location
   *
   * Requires one of the following permissions: MANAGE_INVENTORY_LOCATIONS.
   */
  updateInventoryLocation: Maybe<UpdateInventoryLocation>
  /**
   * Update an existing private product group
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updatePrivateProductGroup: Maybe<UpdatePrivateProductGroup>
  /**
   * Updates product draft.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateProductDraft: Maybe<UpdateProductDraft>
  /**
   * Updates product draft.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateProductDraftVariant: Maybe<UpdateProductDraftVariant>
  /**
   * Update Sendcloud configuration.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateSendcloudConfiguration: Maybe<UpdateSendcloudConfiguration>
  /**
   * Update ShipStation configuration.
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  updateShipstationConfiguration: Maybe<UpdateShipStationConfiguration>
  /**
   * Update a tax group
   *
   * Requires one of the following permissions: MANAGE_TAX_GROUPS.
   */
  updateTaxGroup: Maybe<UpdateTaxGroup>
  /**
   * Update user info.
   *
   * Requires one of the following permissions: AUTHENTICATED_USER.
   */
  updateUser: Maybe<UpdateUser>
  /**
   * Upload a new file
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  uploadProductAttributeFile: Maybe<UploadProductAttributeFile>
  /**
   * Create a new product image
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  uploadProductImage: Maybe<UploadProductImage>
  /**
   * Upload logo for store
   *
   * Requires one of the following permissions: AUTHENTICATED_VENDOR.
   */
  uploadStoreLogo: Maybe<UploadStoreLogo>
}

export type MutationAcceptInvitationArgs = {
  invitationToken: Scalars['String']
}

export type MutationAddOrderItemsArgs = {
  input?: InputMaybe<Array<AddOrderItemInput>>
  orderId: Scalars['ID']
}

export type MutationCreateDiscountGroupArgs = {
  input: DiscountGroupInput
}

export type MutationCreateFlavourProfileArgs = {
  image: Scalars['Upload']
  input: FlavourProfileInput
}

export type MutationCreateInventoryItemArgs = {
  input: InventoryItemCreateInput
}

export type MutationCreateInventoryLocationArgs = {
  input: InventoryLocationInput
}

export type MutationCreatePrivateProductGroupArgs = {
  input: CreatePrivateProductGroupInput
}

export type MutationCreateProductDraftArgs = {
  input: ProductDraftCreateInput
}

export type MutationCreateProductDraftVariantArgs = {
  input: ProductDraftVariantCreateInput
}

export type MutationCreateTaxGroupArgs = {
  input: TaxGroupInput
}

export type MutationDeleteDiscountGroupArgs = {
  id: Scalars['ID']
}

export type MutationDeleteInventoryItemArgs = {
  id: Scalars['ID']
}

export type MutationDeleteInventoryLocationArgs = {
  id: Scalars['ID']
}

export type MutationDeletePrivateProductGroupsArgs = {
  ids: Array<Scalars['ID']>
}

export type MutationDeleteTaxGroupArgs = {
  id: Scalars['ID']
}

export type MutationSendCustomerInvitationArgs = {
  customer: Scalars['String']
}

export type MutationToggleIntegrationArgs = {
  active: Scalars['Boolean']
  id: Scalars['ID']
}

export type MutationUpdateBaselinkerConfigurationArgs = {
  input?: InputMaybe<BaselinkerConfigurationUpdateInput>
}

export type MutationUpdateCropsterConfigurationArgs = {
  input?: InputMaybe<CropsterConfigurationUpdateInput>
}

export type MutationUpdateDiscountGroupArgs = {
  id: Scalars['ID']
  input: DiscountGroupInput
}

export type MutationUpdateDraftOrderArgs = {
  id: Scalars['ID']
  input: DraftOrderUpdateInput
}

export type MutationUpdateFlavourProfileArgs = {
  id?: InputMaybe<Scalars['ID']>
  image: Scalars['Upload']
  input: FlavourProfileInput
}

export type MutationUpdateInventoryItemArgs = {
  id: Scalars['ID']
  input: InventoryItemInput
}

export type MutationUpdateInventoryItemIntegrationMetaArgs = {
  id: Scalars['ID']
  integrationCode: IntegrationCode
  metadata: Scalars['JSONString']
}

export type MutationUpdateInventoryLocationArgs = {
  id: Scalars['ID']
  input: InventoryLocationInput
}

export type MutationUpdatePrivateProductGroupArgs = {
  id: Scalars['ID']
  input: UpdatePrivateProductGroupInput
}

export type MutationUpdateProductDraftArgs = {
  id: Scalars['ID']
  input: ProductDraftUpdateInput
}

export type MutationUpdateProductDraftVariantArgs = {
  id: Scalars['ID']
  input: ProductDraftVariantUpdateInput
}

export type MutationUpdateSendcloudConfigurationArgs = {
  input?: InputMaybe<SendcloudConfigurationUpdateInput>
}

export type MutationUpdateShipstationConfigurationArgs = {
  input?: InputMaybe<ShipStationConfigurationUpdateInput>
}

export type MutationUpdateTaxGroupArgs = {
  id: Scalars['ID']
  input: TaxGroupInput
}

export type MutationUpdateUserArgs = {
  input: UserUpdateInput
}

export type MutationUploadProductAttributeFileArgs = {
  file: Scalars['Upload']
}

export type MutationUploadProductImageArgs = {
  image: Scalars['Upload']
}

export type MutationUploadStoreLogoArgs = {
  logo: Scalars['Upload']
}

/** Represents an order in various states (draft, quote, confirmed, etc). */
export type Order = {
  __typename: 'Order'
  activities: Array<Maybe<Activity>>
  billingAddress: Maybe<BillingAddress>
  createdAt: Scalars['DateTime']
  customer: Maybe<ContactInformation>
  /** Represents when the order planned to be delivered. */
  deliveryDate: Maybe<Scalars['Date']>
  fulfillmentStatus: FulfillmentStatus
  id: Scalars['ID']
  invoices: Array<Invoice>
  items: Array<OrderItem>
  /**
   * Public metadata. Use `keys` to control which fields you want to include. The default is to include everything.
   *
   *
   */
  metadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  metafield: Maybe<Scalars['Metadata']>
  notes: Maybe<Scalars['String']>
  number: Scalars['String']
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: OrderPaymentStatus
  payments: Array<Payment>
  shippingAddress: Maybe<ShippingAddress>
  shippingMethod: ShippingMethod
  status: OrderStatus
  store: Store
  team: Maybe<Team>
  title: Scalars['String']
  total: TaxedMoney
  totalPlatformCommission: Money
  updatedAt: Scalars['DateTime']
}

/** Represents an order in various states (draft, quote, confirmed, etc). */
export type OrderMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

/** Represents an order in various states (draft, quote, confirmed, etc). */
export type OrderMetafieldArgs = {
  key: Scalars['String']
}

export type OrderCountableConnection = {
  __typename: 'OrderCountableConnection'
  edges: Array<OrderCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type OrderCountableEdge = {
  __typename: 'OrderCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Order
}

export enum OrderDirection {
  /** Specifies an ascending sort order. */
  ASC = 'ASC',
  /** Specifies a descending sort order. */
  DESC = 'DESC',
}

export type OrderError = {
  __typename: 'OrderError'
  code: CommonErrorCodes
  field: Scalars['String']
  message: Maybe<Scalars['String']>
}

export type OrderItem = {
  __typename: 'OrderItem'
  discountRate: Scalars['Decimal']
  id: Scalars['ID']
  /**
   * Public metadata. Use `keys` to control which fields you want to include. The default is to include everything.
   *
   *
   */
  metadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  metafield: Maybe<Scalars['Metadata']>
  originalPrice: Money
  platformCommission: Money
  platformCommissionRate: Scalars['Decimal']
  product: Maybe<Product>
  /** Product name at the moment of purchase. */
  productName: Maybe<Scalars['String']>
  quantity: Scalars['Int']
  /** Product code at the moment of purchase. */
  sku: Maybe<Scalars['String']>
  total: TaxedMoney
  type: OrderItemType
}

export type OrderItemMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type OrderItemMetafieldArgs = {
  key: Scalars['String']
}

export type OrderItemProductArgs = {
  language: Language
}

/** An enumeration. */
export enum OrderItemType {
  REGULAR = 'REGULAR',
  SHIPPING_COST = 'SHIPPING_COST',
}

/** An enumeration. */
export enum OrderPaymentStatus {
  CANCELED = 'CANCELED',
  NOT_PAID = 'NOT_PAID',
  OVERPAID = 'OVERPAID',
  OVERREFUNDED = 'OVERREFUNDED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  REFUNDED = 'REFUNDED',
}

export type OrderSearch = {
  /** Where platform should search in. */
  searchIn: OrderSearchInField
  /** Search text. */
  value: Scalars['String']
}

export enum OrderSearchInField {
  ALL = 'ALL',
  NAME = 'NAME',
  NUMBER = 'NUMBER',
}

export enum OrderSortField {
  /** Sort order by created time. */
  CREATED = 'CREATED',
  /** Sort order by number. */
  NUMBER = 'NUMBER',
}

export type OrderSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort orders by the selected field. */
  field: OrderSortField
}

/**
 *
 *     DRAFT - Reserved for future use
 *     UNCONFIRMED - Not ready for fulfillment (start delivery).
 *     CONFIRMED - Ready for fulfillment (start delivery).
 *
 */
export enum OrderStatus {
  CONFIRMED = 'CONFIRMED',
  DRAFT = 'DRAFT',
  UNCONFIRMED = 'UNCONFIRMED',
}

export type Pdf = File & {
  __typename: 'PDF'
  /** Path to the file */
  url: Scalars['String']
}

export type Page = {
  __typename: 'Page'
  endpointSlug: Scalars['String']
  images: Array<CustomPageImage>
  pageType: PageType
  storeSlug: Scalars['String']
  texts: Array<CustomPageText>
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>
}

/** An enumeration. */
export enum PageType {
  LOGIN = 'LOGIN',
}

export type Payer = {
  __typename: 'Payer'
  active: Scalars['Boolean']
  id: Scalars['ID']
  /**
   * Public metadata. Use `keys` to control which fields you want to include. The default is to include everything.
   *
   *
   */
  metadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  metafield: Maybe<Scalars['Metadata']>
  /** Customer unique identifier on payment service platform. */
  pspCustomerId: Maybe<Scalars['String']>
  team: Team
  user: Maybe<User>
}

export type PayerMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type PayerMetafieldArgs = {
  key: Scalars['String']
}

export type Payment = {
  __typename: 'Payment'
  abandoned: Scalars['Boolean']
  capturedAmount: Money
  createdAt: Scalars['DateTime']
  gateway: Scalars['String']
  id: Scalars['ID']
  payer: Maybe<Payer>
  paymentMethod: Maybe<PaymentMethod>
  /** Represents payment unique identifier on the payment service side. */
  pspReference: Maybe<Scalars['String']>
  status: PaymentStatus
  total: Money
  transactions: Array<Maybe<PaymentTransaction>>
  updatedAt: Scalars['DateTime']
}

export type PaymentMethod = {
  __typename: 'PaymentMethod'
  enabled: Scalars['Boolean']
  fingerprint: Maybe<Scalars['String']>
  gateway: Scalars['String']
  id: Scalars['ID']
  /**
   * Public metadata. Use `keys` to control which fields you want to include. The default is to include everything.
   *
   *
   */
  metadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  metafield: Maybe<Scalars['Metadata']>
  payer: Payer
  paymentType: PaymentType
  token: Maybe<Scalars['String']>
  tokenLabel: Maybe<Scalars['String']>
}

export type PaymentMethodMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type PaymentMethodMetafieldArgs = {
  key: Scalars['String']
}

/**
 *
 *     Payment statuses state.
 *     Each status represents in which state the payment now.
 *     Possible statuses:
 *         - DRAFT - Initial state of payment, when it was created and all steps
 *         of payment finished, except webhook call.
 *         - CAPTURED - Funds were taken off from customers source
 *         - PARTIALLY_REFUNDED - Part of funds, that were charged from customer,
 *         were refunded
 *         - FULLY_REFUNDED - All funds, that were charged from customer,
 *         were refunded
 *         - FAILED - The payment was refused by user.
 *
 */
export enum PaymentStatus {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  AUTHORIZED = 'AUTHORIZED',
  CANCELED = 'CANCELED',
  CAPTURED = 'CAPTURED',
  DEFERRED = 'DEFERRED',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  FULLY_REFUNDED = 'FULLY_REFUNDED',
  PARTIALLY_CAPTURED = 'PARTIALLY_CAPTURED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
}

export type PaymentTransaction = {
  __typename: 'PaymentTransaction'
  id: Scalars['ID']
  isSuccess: Scalars['Boolean']
  kind: TransactionKind
  /**
   * Public metadata. Use `keys` to control which fields you want to include. The default is to include everything.
   *
   *
   */
  metadata: Maybe<Scalars['Metadata']>
  /**
   * A single key from public metadata.
   *
   * Tip: Use GraphQL aliases to fetch multiple keys.
   *
   *
   */
  metafield: Maybe<Scalars['Metadata']>
  token: Maybe<Scalars['String']>
  total: Money
}

export type PaymentTransactionMetadataArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>
}

export type PaymentTransactionMetafieldArgs = {
  key: Scalars['String']
}

/** An enumeration. */
export enum PaymentType {
  BANK_TRANSFER = 'BANK_TRANSFER',
  CARD = 'CARD',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
  PRZELEWY24 = 'PRZELEWY24',
  RECURRING = 'RECURRING',
}

/**
 * This type could be used to represent Point on any map.
 *
 * Type to represent `django.contrib.gis.db.models.PointField`.
 */
export type Point = {
  __typename: 'Point'
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type PrivateProductGroup = {
  __typename: 'PrivateProductGroup'
  customers: Array<Customer>
  id: Scalars['ID']
  inventoryItems: Array<InventoryItem>
  name: Scalars['String']
}

export type PrivateProductGroupCountableConnection = {
  __typename: 'PrivateProductGroupCountableConnection'
  edges: Array<PrivateProductGroupCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type PrivateProductGroupCountableEdge = {
  __typename: 'PrivateProductGroupCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: PrivateProductGroup
}

/** An enumeration. */
export enum ProcessingType {
  ANAEROBIC = 'ANAEROBIC',
  HONEY = 'HONEY',
  NATURAL = 'NATURAL',
  WASHED = 'WASHED',
}

/** Contains information about a product and its offers coming from different vendors. */
export type Product = {
  __typename: 'Product'
  bodyHtml: Scalars['String']
  categories: Array<Category>
  coffeeInfo: Maybe<CoffeeInfo>
  hsCode: Maybe<Scalars['String']>
  id: Scalars['ID']
  manufacturer: Manufacturer
  media: Array<Maybe<Media>>
  name: Scalars['String']
  offers: Array<ProductOffer>
  relatedProducts: Array<Maybe<RelatedProductType>>
  slug: Scalars['String']
  subscribed: Scalars['Boolean']
  /** Used for shipping. */
  weight: Weight
}

/**
 * Represents a single attribute of the Product Family.
 *
 * Describes the abstract attribute that Product of chosen Family can or must have.
 *
 * Attrs:
 *     - isRequired: If set, Attribute Value must be specified for Product,
 *       otherwise it's optional
 *     - description: A help text that can be displayed for end user
 *     - dataType: specifies the type of Argument Value
 *     - choices: list the choices that can be selected for CHOICE / MULTI_CHOICE
 *       Attributes
 *     - reference_type: provides the ContentType of the objects that can be selected
 *         for REFERENCE / MULTI_REFERENCE Attributes
 */
export type ProductAttribute = {
  __typename: 'ProductAttribute'
  choices: Maybe<Array<Maybe<ProductAttributeChoice>>>
  dataType: AttributeType
  description: Scalars['String']
  id: Scalars['ID']
  isRequired: Scalars['Boolean']
  isVariant: Scalars['Boolean']
  name: Scalars['String']
  referenceType: Maybe<ReferenceType>
  slug: Scalars['String']
}

/** Represents a choice that can be selected as a value for CHOICE / MULTI_CHOICE attributes. */
export type ProductAttributeChoice = {
  __typename: 'ProductAttributeChoice'
  id: Scalars['ID']
  slug: Maybe<Scalars['String']>
  value: Maybe<Scalars['String']>
}

export type ProductAttributeFile = File & {
  __typename: 'ProductAttributeFile'
  id: Scalars['ID']
  /** Path to the file */
  url: Scalars['String']
}

export type ProductAttributeValue = {
  __typename: 'ProductAttributeValue'
  dataType: AttributeType
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
  value: Maybe<Scalars['String']>
}

export type ProductAttributeValueInput = {
  dataType?: InputMaybe<AttributeType>
  slug: Scalars['String']
  value?: InputMaybe<Scalars['String']>
}

export type ProductAvailability = {
  __typename: 'ProductAvailability'
  availabilityType: AvailabilityType
  fulfillmentTime: Array<FulfillmentTime>
  leadTimeDays: Scalars['Int']
  quantity: Scalars['Int']
}

export type ProductCountableConnection = {
  __typename: 'ProductCountableConnection'
  edges: Array<ProductCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type ProductCountableEdge = {
  __typename: 'ProductCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Product
}

export type ProductDraft = {
  __typename: 'ProductDraft'
  attributes: Array<ProductAttributeValue>
  categories: Array<Category>
  description: Scalars['String']
  family: ProductFamily
  id: Scalars['ID']
  images: Array<ProductImage>
  manufacturer: Manufacturer
  /** Name of the product */
  name: Scalars['String']
  slug: Scalars['String']
  variants: Array<ProductDraftVariant>
  weight: Maybe<Weight>
}

export type ProductDraftCountableConnection = {
  __typename: 'ProductDraftCountableConnection'
  edges: Array<ProductDraftCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type ProductDraftCountableEdge = {
  __typename: 'ProductDraftCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: ProductDraft
}

export type ProductDraftCreateInput = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  description?: InputMaybe<Scalars['String']>
  familyId: Scalars['ID']
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  inventoryItem?: InputMaybe<InventoryItemInput>
  language: Language
  manufacturerId: Scalars['ID']
  name: Scalars['String']
  slug?: InputMaybe<Scalars['String']>
  weight?: InputMaybe<WeightInput>
}

export type ProductDraftFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export enum ProductDraftSortField {
  /** Sort product drafts by name. */
  NAME = 'NAME',
}

export type ProductDraftSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort product drafts by the selected field. */
  field: ProductDraftSortField
}

export type ProductDraftUpdateInput = {
  attributes?: InputMaybe<Array<ProductAttributeValueInput>>
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  description?: InputMaybe<Scalars['String']>
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  inventoryItem?: InputMaybe<InventoryItemInput>
  language: Language
  manufacturerId?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  weight?: InputMaybe<WeightInput>
}

export type ProductDraftVariant = {
  __typename: 'ProductDraftVariant'
  attributes: Array<ProductAttributeValue>
  description: Scalars['String']
  id: Scalars['ID']
  images: Array<ProductImage>
  inventoryItem: Maybe<InventoryItem>
  /** Name of the product */
  name: Scalars['String']
  productDraft: ProductDraft
  slug: Scalars['String']
  weight: Maybe<Weight>
}

export type ProductDraftVariantCountableConnection = {
  __typename: 'ProductDraftVariantCountableConnection'
  edges: Array<ProductDraftVariantCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type ProductDraftVariantCountableEdge = {
  __typename: 'ProductDraftVariantCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: ProductDraftVariant
}

export type ProductDraftVariantCreateInput = {
  attributes?: InputMaybe<Array<InputMaybe<ProductAttributeValueInput>>>
  description?: InputMaybe<Scalars['String']>
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  inventoryItem?: InputMaybe<InventoryItemInput>
  language: Language
  name?: InputMaybe<Scalars['String']>
  productDraftId: Scalars['ID']
  slug?: InputMaybe<Scalars['String']>
  weight?: InputMaybe<WeightInput>
}

export type ProductDraftVariantUpdateInput = {
  attributes?: InputMaybe<Array<ProductAttributeValueInput>>
  description?: InputMaybe<Scalars['String']>
  imageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>
  inventoryItem?: InputMaybe<InventoryItemInput>
  language: Language
  name?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  weight?: InputMaybe<WeightInput>
}

/** Describes all the generic attributes that a product can or must have. Product / Product Draft can only belong to single Family. */
export type ProductFamily = {
  __typename: 'ProductFamily'
  attributes: Array<Maybe<ProductAttribute>>
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
}

export type ProductFamilyCountableConnection = {
  __typename: 'ProductFamilyCountableConnection'
  edges: Array<ProductFamilyCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type ProductFamilyCountableEdge = {
  __typename: 'ProductFamilyCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: ProductFamily
}

export type ProductFamilyFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export enum ProductFamilySortField {
  /** Sort product families by name. */
  NAME = 'NAME',
}

export type ProductFamilySortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort product families by the selected field. */
  field: ProductFamilySortField
}

export type ProductFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>
  categorySlugs?: InputMaybe<Array<Scalars['String']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  slugs?: InputMaybe<Array<Scalars['String']>>
}

export type ProductImage = File & {
  __typename: 'ProductImage'
  id: Scalars['ID']
  /** Path to the file */
  url: Scalars['String']
}

/** Contains information about a product offered by a specific vendor. */
export type ProductOffer = {
  __typename: 'ProductOffer'
  availability: Array<ProductAvailability>
  catalogPrice: Money
  defaultShippingMethod: Maybe<StoreShippingMethod>
  discountRate: Scalars['Decimal']
  quantity: Scalars['Int']
  /** Contains information about taxed price if available in the given market. */
  sellingPrice: TaxedMoney
  sku: Scalars['String']
  store: Store
  taxRate: Maybe<Scalars['Decimal']>
}

export type Query = {
  __typename: 'Query'
  /** Baselinker API configuration associated with the current store */
  baselinkerConfiguration: Maybe<BaselinkerConfiguration>
  categories: Maybe<CategoryCountableConnection>
  /** Cropster API configuration associated with the current store */
  cropsterConfiguration: Maybe<CropsterConfiguration>
  customerOrders: Maybe<OrderCountableConnection>
  customers: Maybe<CustomerCountableConnection>
  discountGroups: Maybe<DiscountGroupCountableConnection>
  flavourProfiles: Maybe<FlavourProfileCountableConnection>
  inventoryItems: Maybe<InventoryItemCountableConnection>
  inventoryLocations: Maybe<InventoryLocationCountableConnection>
  manufacturers: Maybe<ManufacturerCountableConnection>
  /** Resolve stores from vendor perspective. */
  ownStores: Maybe<StoreCountableConnection>
  /** Resolve page customization for the stores. */
  page: Maybe<Page>
  privateProductGroups: Maybe<PrivateProductGroupCountableConnection>
  /** Private products available only for specific customers */
  privateProducts: Maybe<ProductCountableConnection>
  productDraftVariants: Maybe<ProductDraftVariantCountableConnection>
  productDrafts: Maybe<ProductDraftCountableConnection>
  productFamilies: Maybe<ProductFamilyCountableConnection>
  /** Public products available for all customers */
  products: Maybe<ProductCountableConnection>
  /** QuickBooks API configuration associated with the current store */
  quickbooksConfiguration: Maybe<QuickbooksConfiguration>
  /** Sendcloud API configuration associated with the current store */
  sendcloudConfiguration: Maybe<SendcloudConfiguration>
  /** ShipStation API configuration associated with the current store */
  shipstationConfiguration: Maybe<ShipStationConfiguration>
  storeOrders: Maybe<OrderCountableConnection>
  /** Resolve stores from customer perspective. */
  stores: Maybe<StoreCountableConnection>
  taxGroups: Maybe<TaxGroupCountableConnection>
}

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<CategoryFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language: Language
  last?: InputMaybe<Scalars['Int']>
  parentId?: InputMaybe<Scalars['ID']>
  sortBy?: InputMaybe<CategorySortingInput>
}

export type QueryCustomerOrdersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<CustomerOrderFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<OrderSortingInput>
}

export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<CustomerFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<CustomerSortingInput>
}

export type QueryDiscountGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DiscountGroupFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryFlavourProfilesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<FlavourProfileFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Language>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<FlavourProfileSortingInput>
}

export type QueryInventoryItemsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<InventoryItemFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Language>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<InventoryItemSortingInput>
}

export type QueryInventoryLocationsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<InventoryLocationFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<InventoryLocationSortingInput>
}

export type QueryManufacturersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<ManufacturerFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Language>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<ManufacturerSortingInput>
}

export type QueryOwnStoresArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPageArgs = {
  endpointSlug: Scalars['String']
  pageType?: InputMaybe<PageType>
}

export type QueryPrivateProductGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryPrivateProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<ProductFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language: Language
  last?: InputMaybe<Scalars['Int']>
  marketplaceOnly?: InputMaybe<Scalars['Boolean']>
  storeId?: InputMaybe<Scalars['ID']>
}

export type QueryProductDraftVariantsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Language>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryProductDraftsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<ProductDraftFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Language>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<ProductDraftSortingInput>
}

export type QueryProductFamiliesArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<ProductFamilyFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language?: InputMaybe<Language>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<ProductFamilySortingInput>
}

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<ProductFilterInput>
  first?: InputMaybe<Scalars['Int']>
  language: Language
  last?: InputMaybe<Scalars['Int']>
  marketplaceOnly?: InputMaybe<Scalars['Boolean']>
  storeId?: InputMaybe<Scalars['ID']>
}

export type QueryStoreOrdersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<VendorOrderFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<OrderSortingInput>
}

export type QueryStoresArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<StoreFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

export type QueryTaxGroupsArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<TaxGroupFilterInput>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<TaxGroupSortingInput>
}

/** Contains configuration of an integration with QuickBooks. */
export type QuickbooksConfiguration = {
  __typename: 'QuickbooksConfiguration'
  id: Scalars['ID']
  integration: Integration
}

/** Represents Content Types of objects that can be selected as a value for REFERENCE / MULTI_REFERENCE Attributes. */
export enum ReferenceType {
  PIM_COFFEEORIGIN = 'PIM_COFFEEORIGIN',
  STORE_FLAVOURPROFILE = 'STORE_FLAVOURPROFILE',
  STORE_MANUFACTURER = 'STORE_MANUFACTURER',
}

export type RelatedProduct = {
  __typename: 'RelatedProduct'
  current: Scalars['Boolean']
  /** Human readable value */
  description: Scalars['String']
  product: Product
  /** Specific value, e.g. hex code for color */
  value: Scalars['String']
}

export type RelatedProductType = {
  __typename: 'RelatedProductType'
  type: Scalars['String']
  variants: Array<RelatedProduct>
}

/** An enumeration. */
export enum RoastProfile {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  OMNIROAST = 'OMNIROAST',
}

/**
 * Send invitation to an existing customer that haven't registered on the platform yet.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type SendCustomerInvitation = {
  __typename: 'SendCustomerInvitation'
  errors: Array<CustomerError>
}

/** Contains configuration of an integration with Sendcloud. */
export type SendcloudConfiguration = {
  __typename: 'SendcloudConfiguration'
  id: Scalars['ID']
  integration: Integration
  publicKey: Scalars['String']
  secretKey: Scalars['String']
}

export type SendcloudConfigurationUpdateInput = {
  publicKey: Scalars['String']
  secretKey: Scalars['String']
}

/** Contains configuration of an integration with ShipStation. */
export type ShipStationConfiguration = {
  __typename: 'ShipStationConfiguration'
  apiKey: Scalars['String']
  apiSecret: Scalars['String']
  id: Scalars['ID']
  integration: Integration
}

export type ShipStationConfigurationUpdateInput = {
  apiKey: Scalars['String']
  apiSecret: Scalars['String']
}

export type ShippingAddress = {
  __typename: 'ShippingAddress'
  city: Scalars['String']
  companyName: Maybe<Scalars['String']>
  country: Scalars['String']
  email: Maybe<Scalars['String']>
  houseNumber: Scalars['String']
  location: Maybe<Point>
  name: Scalars['String']
  phone: Maybe<Scalars['String']>
  street: Scalars['String']
  zipCode: Scalars['String']
}

/** An enumeration. */
export enum ShippingMethod {
  DB_SCHENKER = 'DB_SCHENKER',
  DELIVERY_BY_MERCHANT = 'DELIVERY_BY_MERCHANT',
  DHL = 'DHL',
  DPD = 'DPD',
  FEDEX = 'FEDEX',
  GLS = 'GLS',
  INPOST = 'INPOST',
  JAS_FBG = 'JAS_FBG',
  PACZKOMATY = 'PACZKOMATY',
  PEKAES = 'PEKAES',
  POCZTEX = 'POCZTEX',
  SELF_PICKUP = 'SELF_PICKUP',
  UPS = 'UPS',
}

/** Contains information about a store on the platform. */
export type Store = {
  __typename: 'Store'
  categories: Array<Category>
  id: Scalars['ID']
  includeTaxesInPrices: Scalars['Boolean']
  legalName: Scalars['String']
  logo: Maybe<Image>
  name: Scalars['String']
}

/** Contains information about a store on the platform. */
export type StoreCategoriesArgs = {
  language: Language
}

export type StoreCountableConnection = {
  __typename: 'StoreCountableConnection'
  edges: Array<StoreCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type StoreCountableEdge = {
  __typename: 'StoreCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: Store
}

export type StoreFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  slugs?: InputMaybe<Array<Scalars['String']>>
}

/** Shipping method for a store. */
export type StoreShippingMethod = {
  __typename: 'StoreShippingMethod'
  /** Date when customer will receive the package. */
  expectedDeliveryDate: Scalars['Date']
  /** Date when the package will be shipped. */
  expectedShippingDate: Scalars['Date']
  /** Time in days required to deliver package from storage to customer. */
  expectedShippingTime: Scalars['Int']
  id: Scalars['ID']
  /** Latest time in hours when the item could be ordered to be shipped the same day. */
  shipTodayByHour: Scalars['Int']
  timezone: Scalars['String']
}

export type TaxGroup = {
  __typename: 'TaxGroup'
  code: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  rate: Scalars['Decimal']
}

export type TaxGroupCountableConnection = {
  __typename: 'TaxGroupCountableConnection'
  edges: Array<TaxGroupCountableEdge>
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** A total count of items in the collection. */
  totalCount: Maybe<Scalars['Int']>
}

export type TaxGroupCountableEdge = {
  __typename: 'TaxGroupCountableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node: TaxGroup
}

export type TaxGroupFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  search?: InputMaybe<Scalars['String']>
}

export type TaxGroupInput = {
  code?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  rate: Scalars['Decimal']
}

export enum TaxGroupSortField {
  /** Sort tax groups by code. */
  CODE = 'CODE',
  /** Sort tax groups by name. */
  NAME = 'NAME',
  /** Sort tax groups by rate. */
  RATE = 'RATE',
}

export type TaxGroupSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: OrderDirection
  /** Sort tax groupds by the selected field. */
  field: TaxGroupSortField
}

export type TaxedMoney = {
  __typename: 'TaxedMoney'
  currency: Currency
  gross: Maybe<Money>
  net: Money
  tax: Maybe<Money>
}

export type Team = {
  __typename: 'Team'
  accountType: Maybe<AccountType>
  billingAddress: Maybe<BillingAddress>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  keyAccountManager: Maybe<ContactInformation>
  marketplaceEnabled: Scalars['Boolean']
  users: Array<User>
}

/**
 * Enable/disable integration. If the integration is not configured, trying to enable/disable it will return an error.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type ToggleIntegration = {
  __typename: 'ToggleIntegration'
  errors: Array<Error>
  integration: Maybe<Integration>
}

/**
 *
 *     Transaction types in execution order:
 *         - AUTH - The amount is reserved on customers source, but haven't moved
 *         to vendor yet
 *         - CAPTURE - A transfer of amount that was reserved during AUTH stage
 *         - VOID - Canceled any AUTH or CAPTURE
 *         - REFUND - Full or partial funds returning to customer
 *         - ACTION_TO_CONFIRM - Bank or payment systems needs additional
 *         transaction confirmation
 *         - DEFER - The system doesn't wait any response from payment system.
 *         This status is used for bank transfer for example.
 *
 */
export enum TransactionKind {
  ACTION_TO_CONFIRM = 'ACTION_TO_CONFIRM',
  AUTH = 'AUTH',
  CAPTURE = 'CAPTURE',
  CREATED = 'CREATED',
  DEFER = 'DEFER',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REFUND = 'REFUND',
  VOID = 'VOID',
}

/**
 * Update Baselinker configuration.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateBaselinkerConfiguration = {
  __typename: 'UpdateBaselinkerConfiguration'
  baselinkerConfiguration: Maybe<BaselinkerConfiguration>
  errors: Array<Error>
}

/**
 * Update Cropster configuration.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateCropsterConfiguration = {
  __typename: 'UpdateCropsterConfiguration'
  cropsterConfiguration: Maybe<CropsterConfiguration>
  errors: Array<Error>
}

/**
 * Update a discount group
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateDiscountGroup = {
  __typename: 'UpdateDiscountGroup'
  discountGroup: Maybe<DiscountGroup>
  errors: Array<Error>
}

/**
 * Update draft order.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateDraftOrder = {
  __typename: 'UpdateDraftOrder'
  errors: Array<OrderError>
  order: Maybe<Order>
}

/**
 * Update a flavour profile
 *
 * Requires one of the following permissions: MANAGE_FLAVOUR_PROFILES.
 */
export type UpdateFlavourProfile = {
  __typename: 'UpdateFlavourProfile'
  errors: Array<Error>
  flavourProfile: Maybe<FlavourProfile>
}

/**
 * Update an inventory item
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateInventoryItem = {
  __typename: 'UpdateInventoryItem'
  errors: Array<Error>
  inventoryItem: Maybe<InventoryItem>
}

/**
 * Update Inventory Item metadata for a specific integration.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateInventoryItemIntegrationMetadata = {
  __typename: 'UpdateInventoryItemIntegrationMetadata'
  errors: Array<Error>
  inventoryItem: Maybe<InventoryItem>
}

/**
 * Update an inventory location
 *
 * Requires one of the following permissions: MANAGE_INVENTORY_LOCATIONS.
 */
export type UpdateInventoryLocation = {
  __typename: 'UpdateInventoryLocation'
  errors: Array<Error>
  inventoryLocation: Maybe<InventoryLocation>
}

/**
 * Update an existing private product group
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdatePrivateProductGroup = {
  __typename: 'UpdatePrivateProductGroup'
  errors: Array<Error>
  privateProductGroup: Maybe<PrivateProductGroup>
}

export type UpdatePrivateProductGroupInput = {
  addCustomerIds?: InputMaybe<Array<Scalars['ID']>>
  addInventoryItemIds?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  removeCustomerIds?: InputMaybe<Array<Scalars['ID']>>
  removeInventoryItemIds?: InputMaybe<Array<Scalars['ID']>>
}

/**
 * Updates product draft.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateProductDraft = {
  __typename: 'UpdateProductDraft'
  errors: Array<Error>
  productDraft: Maybe<ProductDraft>
}

/**
 * Updates product draft.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateProductDraftVariant = {
  __typename: 'UpdateProductDraftVariant'
  errors: Array<Error>
  productDraftVariant: Maybe<ProductDraftVariant>
}

/**
 * Update Sendcloud configuration.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateSendcloudConfiguration = {
  __typename: 'UpdateSendcloudConfiguration'
  errors: Array<Error>
  sendcloudConfiguration: Maybe<SendcloudConfiguration>
}

/**
 * Update ShipStation configuration.
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UpdateShipStationConfiguration = {
  __typename: 'UpdateShipStationConfiguration'
  errors: Array<Error>
  shipstationConfiguration: Maybe<ShipStationConfiguration>
}

/**
 * Update a tax group
 *
 * Requires one of the following permissions: MANAGE_TAX_GROUPS.
 */
export type UpdateTaxGroup = {
  __typename: 'UpdateTaxGroup'
  errors: Array<Error>
  taxGroup: Maybe<TaxGroup>
}

/**
 * Update user info.
 *
 * Requires one of the following permissions: AUTHENTICATED_USER.
 */
export type UpdateUser = {
  __typename: 'UpdateUser'
  errors: Array<Error>
  user: Maybe<User>
}

/**
 * Upload a new file
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UploadProductAttributeFile = {
  __typename: 'UploadProductAttributeFile'
  errors: Array<Error>
  productAttributeFile: Maybe<ProductAttributeFile>
}

/**
 * Create a new product image
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UploadProductImage = {
  __typename: 'UploadProductImage'
  errors: Array<Error>
  productImage: Maybe<ProductImage>
}

/**
 * Upload logo for store
 *
 * Requires one of the following permissions: AUTHENTICATED_VENDOR.
 */
export type UploadStoreLogo = {
  __typename: 'UploadStoreLogo'
  errors: Array<Error>
  logo: Maybe<Image>
}

export type User = {
  __typename: 'User'
  email: Scalars['String']
  firstName: Scalars['String']
  fullName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  phone: Maybe<Scalars['String']>
  timezone: Maybe<Scalars['String']>
}

export type UserUpdateInput = {
  /** User email. */
  email?: InputMaybe<Scalars['String']>
  /** User first name. */
  firstName?: InputMaybe<Scalars['String']>
  /** User last name. */
  lastName?: InputMaybe<Scalars['String']>
  /** User phone number. */
  phone?: InputMaybe<Scalars['String']>
  /** User timezone. */
  timezone?: InputMaybe<Scalars['String']>
}

export type VendorOrderFilterInput = {
  createdAt?: InputMaybe<DateTimeRangeInput>
  deliveryDate?: InputMaybe<DateRangeInput>
  fulfillmentStatus?: InputMaybe<Array<FulfillmentStatus>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  paymentStatus?: InputMaybe<Array<OrderPaymentStatus>>
  search?: InputMaybe<OrderSearch>
  status?: InputMaybe<Array<OrderStatus>>
}

/** Represents weight value in a specific weight unit. */
export type Weight = {
  __typename: 'Weight'
  /** Weight unit. */
  unit: WeightUnitEnum
  /** Weight value. */
  value: Scalars['Float']
}

export type WeightInput = {
  /** Weight unit. */
  unit: WeightUnitEnum
  /** Weight value. */
  value: Scalars['Float']
}

/** An enumeration. */
export enum WeightUnitEnum {
  G = 'G',
  KG = 'KG',
  LB = 'LB',
  OZ = 'OZ',
  TONNE = 'TONNE',
}

export type CategoryFragmentFragment = {
  __typename: 'Category'
  id: string
  slug: string
  name: string
  path: string
  icon: CategoryIcon | null
}

export type ErrorFragmentFragment = {
  __typename: 'Error'
  field: string
  code: CommonErrorCodes
  message: string | null
}

export type IntegrationFragmentFragment = {
  __typename: 'Integration'
  id: string
  code: IntegrationCode
  active: boolean
}

export type ManufacturerFragmentFragment = { __typename: 'Manufacturer'; id: string; name: string }

export type MediaFragmentFragment = { __typename: 'Media'; mediaType: MediaType; url: string }

export type MoneyFragmentFragment = { __typename: 'Money'; amount: any; currency: Currency | null }

export type NavigationStoreFragmentFragment = {
  __typename: 'Store'
  id: string
  name: string
  logo: { __typename: 'Image'; url: string } | null
}

export type CropsterOrderableProductsFragmentFragment = {
  __typename: 'CropsterOrderableProduct'
  id: string
  name: string
}

export type PageInfoFragmentFragment = {
  __typename: 'PageInfo'
  endCursor: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string | null
}

export type ProductFragmentFragment = { __typename: 'Product'; id: string; name: string }

export type ProductAvailabilityFragmentFragment = {
  __typename: 'ProductAvailability'
  quantity: number
  leadTimeDays: number
  availabilityType: AvailabilityType
  fulfillmentTime: Array<{
    __typename: 'FulfillmentTime'
    id: string
    value: number
    fulfillmentTimeType: FulfillmentTimeType
  }>
}

export type RelatedProductFragmentFragment = {
  __typename: 'RelatedProduct'
  description: string
  value: string
  current: boolean
  product: {
    __typename: 'Product'
    id: string
    slug: string
    name: string
    bodyHtml: string
    subscribed: boolean
    manufacturer: { __typename: 'Manufacturer'; id: string; name: string }
    media: Array<{ __typename: 'Media'; mediaType: MediaType; url: string } | null>
    offers: Array<{
      __typename: 'ProductOffer'
      sku: string
      discountRate: any
      quantity: number
      store: {
        __typename: 'Store'
        id: string
        name: string
        legalName: string
        includeTaxesInPrices: boolean
        logo: { __typename: 'Image'; url: string } | null
        categories: Array<{
          __typename: 'Category'
          id: string
          slug: string
          name: string
          path: string
          icon: CategoryIcon | null
        }>
      }
      catalogPrice: { __typename: 'Money'; amount: any; currency: Currency | null }
      sellingPrice: {
        __typename: 'TaxedMoney'
        currency: Currency
        gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        net: { __typename: 'Money'; amount: any; currency: Currency | null }
        tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
      }
      availability: Array<{
        __typename: 'ProductAvailability'
        quantity: number
        leadTimeDays: number
        availabilityType: AvailabilityType
        fulfillmentTime: Array<{
          __typename: 'FulfillmentTime'
          id: string
          value: number
          fulfillmentTimeType: FulfillmentTimeType
        }>
      }>
    }>
    weight: { __typename: 'Weight'; unit: WeightUnitEnum; value: number }
  }
}

export type StoreFragmentFragment = {
  __typename: 'Store'
  id: string
  name: string
  legalName: string
  includeTaxesInPrices: boolean
  logo: { __typename: 'Image'; url: string } | null
  categories: Array<{
    __typename: 'Category'
    id: string
    slug: string
    name: string
    path: string
    icon: CategoryIcon | null
  }>
}

export type StoreShippingMethodFragmentFragment = {
  __typename: 'StoreShippingMethod'
  id: string
  expectedShippingTime: number
  shipTodayByHour: number
  timezone: string
  expectedShippingDate: any
  expectedDeliveryDate: any
}

export type TaxGroupFragmentFragment = {
  __typename: 'TaxGroup'
  id: string
  name: string
  rate: any
  code: string | null
}

export type TaxedMoneyFragmentFragment = {
  __typename: 'TaxedMoney'
  currency: Currency
  gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
  net: { __typename: 'Money'; amount: any; currency: Currency | null }
  tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
}

export type UserFragmentFragment = {
  __typename: 'User'
  id: string
  email: string
  phone: string | null
  firstName: string
  lastName: string
  fullName: string
}

export type WeightFragmentFragment = { __typename: 'Weight'; unit: WeightUnitEnum; value: number }

export type AcceptInvitationMutationVariables = Exact<{
  invitationToken: Scalars['String']
}>

export type AcceptInvitationMutation = {
  __typename: 'Mutation'
  acceptInvitation: {
    __typename: 'AcceptInvitation'
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateBaselinkerConfigurationMutationVariables = Exact<{
  input: BaselinkerConfigurationUpdateInput
}>

export type UpdateBaselinkerConfigurationMutation = {
  __typename: 'Mutation'
  updateBaselinkerConfiguration: {
    __typename: 'UpdateBaselinkerConfiguration'
    baselinkerConfiguration: {
      __typename: 'BaselinkerConfiguration'
      id: string
      apiKey: string
      newOrderStatusId: number
      integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
    } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateCropsterConfigurationMutationVariables = Exact<{
  input: CropsterConfigurationUpdateInput
}>

export type UpdateCropsterConfigurationMutation = {
  __typename: 'Mutation'
  updateCropsterConfiguration: {
    __typename: 'UpdateCropsterConfiguration'
    cropsterConfiguration: {
      __typename: 'CropsterConfiguration'
      id: string
      orderableProducts: Array<{ __typename: 'CropsterOrderableProduct'; id: string; name: string }>
      integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
    } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateInventoryItemIntegrationMetaMutationVariables = Exact<{
  id: Scalars['ID']
  integrationCode: IntegrationCode
  metadata: Scalars['JSONString']
}>

export type UpdateInventoryItemIntegrationMetaMutation = {
  __typename: 'Mutation'
  updateInventoryItemIntegrationMeta: {
    __typename: 'UpdateInventoryItemIntegrationMetadata'
    inventoryItem: {
      __typename: 'InventoryItem'
      id: string
      integrationMeta: Array<{ __typename: 'InventoryItemIntegrationMeta'; metadata: any | null } | null>
    } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type CreateProductDraftMutationVariables = Exact<{
  input: ProductDraftCreateInput
}>

export type CreateProductDraftMutation = {
  __typename: 'Mutation'
  createProductDraft: {
    __typename: 'CreateProductDraft'
    productDraft: {
      __typename: 'ProductDraft'
      id: string
      name: string
      variants: Array<{
        __typename: 'ProductDraftVariant'
        inventoryItem: { __typename: 'InventoryItem'; id: string } | null
      }>
    } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateProductDraftMutationVariables = Exact<{
  id: Scalars['ID']
  input: ProductDraftUpdateInput
}>

export type UpdateProductDraftMutation = {
  __typename: 'Mutation'
  updateProductDraft: {
    __typename: 'UpdateProductDraft'
    productDraft: { __typename: 'ProductDraft'; id: string; name: string; slug: string } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateSendcloudConfigurationMutationVariables = Exact<{
  input: SendcloudConfigurationUpdateInput
}>

export type UpdateSendcloudConfigurationMutation = {
  __typename: 'Mutation'
  updateSendcloudConfiguration: {
    __typename: 'UpdateSendcloudConfiguration'
    sendcloudConfiguration: {
      __typename: 'SendcloudConfiguration'
      id: string
      publicKey: string
      secretKey: string
      integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
    } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateShipStationConfigurationMutationVariables = Exact<{
  input: ShipStationConfigurationUpdateInput
}>

export type UpdateShipStationConfigurationMutation = {
  __typename: 'Mutation'
  updateShipstationConfiguration: {
    __typename: 'UpdateShipStationConfiguration'
    shipstationConfiguration: {
      __typename: 'ShipStationConfiguration'
      id: string
      apiKey: string
      apiSecret: string
      integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
    } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateInventoryItemMutationVariables = Exact<{
  id: Scalars['ID']
  input: InventoryItemInput
}>

export type UpdateInventoryItemMutation = {
  __typename: 'Mutation'
  updateInventoryItem: {
    __typename: 'UpdateInventoryItem'
    inventoryItem: { __typename: 'InventoryItem'; id: string } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UpdateProductDraftVariantMutationVariables = Exact<{
  id: Scalars['ID']
  input: ProductDraftVariantUpdateInput
}>

export type UpdateProductDraftVariantMutation = {
  __typename: 'Mutation'
  updateProductDraftVariant: {
    __typename: 'UpdateProductDraftVariant'
    productDraftVariant: {
      __typename: 'ProductDraftVariant'
      id: string
      name: string
      slug: string
      productDraft: { __typename: 'ProductDraft'; id: string }
    } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type UploadProductImageMutationVariables = Exact<{
  image: Scalars['Upload']
}>

export type UploadProductImageMutation = {
  __typename: 'Mutation'
  uploadProductImage: {
    __typename: 'UploadProductImage'
    productImage: { __typename: 'ProductImage'; url: string; id: string } | null
    errors: Array<{ __typename: 'Error'; field: string; code: CommonErrorCodes; message: string | null }>
  } | null
}

export type AllCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<CategoryFilterInput>
  sortBy?: InputMaybe<CategorySortingInput>
  language: Language
  parentId?: InputMaybe<Scalars['ID']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type AllCategoriesQuery = {
  __typename: 'Query'
  categories: {
    __typename: 'CategoryCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'CategoryCountableEdge'
      cursor: string
      node: {
        __typename: 'Category'
        id: string
        name: string
        path: string
        subcategories: Array<{
          __typename: 'Category'
          id: string
          name: string
          path: string
          subcategories: Array<{ __typename: 'Category'; id: string; name: string; path: string }>
        }>
      }
    }>
  } | null
}

export type BaselinkerConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type BaselinkerConfigurationQuery = {
  __typename: 'Query'
  baselinkerConfiguration: {
    __typename: 'BaselinkerConfiguration'
    id: string
    apiKey: string
    newOrderStatusId: number
    integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
  } | null
}

export type BriefInventoryLocationsQueryVariables = Exact<{
  filter?: InputMaybe<InventoryLocationFilterInput>
  sortBy?: InputMaybe<InventoryLocationSortingInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type BriefInventoryLocationsQuery = {
  __typename: 'Query'
  inventoryLocations: {
    __typename: 'InventoryLocationCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'InventoryLocationCountableEdge'
      cursor: string
      node: { __typename: 'InventoryLocation'; id: string; name: string }
    }>
  } | null
}

export type CategoriesQueryVariables = Exact<{
  filter?: InputMaybe<CategoryFilterInput>
  sortBy?: InputMaybe<CategorySortingInput>
  language: Language
  parentId?: InputMaybe<Scalars['ID']>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type CategoriesQuery = {
  __typename: 'Query'
  categories: {
    __typename: 'CategoryCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'CategoryCountableEdge'
      cursor: string
      node: { __typename: 'Category'; id: string; slug: string; name: string; path: string; icon: CategoryIcon | null }
    }>
  } | null
}

export type CropsterConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type CropsterConfigurationQuery = {
  __typename: 'Query'
  cropsterConfiguration: {
    __typename: 'CropsterConfiguration'
    id: string
    integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
  } | null
}

export type CropsterOrderableProductQueryVariables = Exact<{ [key: string]: never }>

export type CropsterOrderableProductQuery = {
  __typename: 'Query'
  cropsterConfiguration: {
    __typename: 'CropsterConfiguration'
    id: string
    apiKey: string
    apiSecret: string
    orderableProducts: Array<{ __typename: 'CropsterOrderableProduct'; id: string; name: string }>
    integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
  } | null
}

export type CustomerOrderQueryVariables = Exact<{
  filter?: InputMaybe<CustomerOrderFilterInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<OrderSortingInput>
}>

export type CustomerOrderQuery = {
  __typename: 'Query'
  customerOrders: {
    __typename: 'OrderCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'OrderCountableEdge'
      cursor: string
      node: {
        __typename: 'Order'
        id: string
        number: string
        title: string
        status: OrderStatus
        createdAt: any
        updatedAt: any
        fulfillmentStatus: FulfillmentStatus
        paymentStatus: OrderPaymentStatus
        notes: string | null
        shippingMethod: ShippingMethod
        deliveryDate: any | null
        total: {
          __typename: 'TaxedMoney'
          currency: Currency
          gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          net: { __typename: 'Money'; amount: any; currency: Currency | null }
          tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        }
        store: {
          __typename: 'Store'
          id: string
          name: string
          legalName: string
          logo: { __typename: 'Image'; url: string } | null
        }
        customer: {
          __typename: 'ContactInformation'
          email: string
          firstName: string
          lastName: string
          fullName: string
          phone: string | null
        } | null
        shippingAddress: {
          __typename: 'ShippingAddress'
          street: string
          houseNumber: string
          city: string
          zipCode: string
          name: string
          country: string
          companyName: string | null
          email: string | null
          phone: string | null
        } | null
        billingAddress: {
          __typename: 'BillingAddress'
          street: string
          houseNumber: string
          city: string
          zipCode: string
          name: string
          country: string
          companyName: string | null
          taxId: string | null
        } | null
        paymentMethod: {
          __typename: 'PaymentMethod'
          id: string
          gateway: string
          paymentType: PaymentType
          payer: {
            __typename: 'Payer'
            id: string
            user: {
              __typename: 'User'
              id: string
              email: string
              phone: string | null
              firstName: string
              lastName: string
              fullName: string
            } | null
          }
        } | null
        payments: Array<{
          __typename: 'Payment'
          id: string
          pspReference: string | null
          abandoned: boolean
          status: PaymentStatus
          gateway: string
          createdAt: any
          updatedAt: any
          paymentMethod: {
            __typename: 'PaymentMethod'
            id: string
            gateway: string
            paymentType: PaymentType
            payer: {
              __typename: 'Payer'
              id: string
              user: {
                __typename: 'User'
                id: string
                email: string
                phone: string | null
                firstName: string
                lastName: string
                fullName: string
              } | null
            }
          } | null
          payer: {
            __typename: 'Payer'
            id: string
            user: {
              __typename: 'User'
              id: string
              email: string
              phone: string | null
              firstName: string
              lastName: string
              fullName: string
            } | null
          } | null
          total: { __typename: 'Money'; amount: any; currency: Currency | null }
          capturedAmount: { __typename: 'Money'; amount: any; currency: Currency | null }
          transactions: Array<{
            __typename: 'PaymentTransaction'
            id: string
            token: string | null
            kind: TransactionKind
            isSuccess: boolean
            total: { __typename: 'Money'; amount: any; currency: Currency | null }
          } | null>
        }>
        activities: Array<{
          __typename: 'Activity'
          id: string
          event: ActivityEvent
          createdAt: any
          updatedAt: any
          customerMetadata: any | null
          actor: { __typename: 'ContactInformation'; fullName: string; email: string; phone: string | null } | null
        } | null>
        invoices: Array<{
          __typename: 'Invoice'
          id: string
          number: string
          createdAt: any
          effectiveDate: any | null
          pdfFile: { __typename: 'PDF'; url: string }
          total: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        }>
        items: Array<{
          __typename: 'OrderItem'
          id: string
          productName: string | null
          sku: string | null
          quantity: number
          type: OrderItemType
          total: {
            __typename: 'TaxedMoney'
            currency: Currency
            gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
            net: { __typename: 'Money'; amount: any; currency: Currency | null }
            tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          }
        }>
      }
    }>
  } | null
}

export type CustomersListQueryVariables = Exact<{
  filter?: InputMaybe<CustomerFilterInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<CustomerSortingInput>
}>

export type CustomersListQuery = {
  __typename: 'Query'
  customers: {
    __typename: 'CustomerCountableConnection'
    totalCount: number | null
    totalPerStatus: any | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'CustomerCountableEdge'
      cursor: string
      node: {
        __typename: 'Customer'
        id: string
        status: FrameContractStatus
        lastOrderTime: any | null
        createdAt: any
        name: string | null
        user: {
          __typename: 'ContactInformation'
          email: string
          firstName: string
          lastName: string
          fullName: string
        } | null
        invite: { __typename: 'CustomerInvite'; firstName: string; lastName: string; email: string } | null
        store: { __typename: 'Store'; id: string; name: string }
        ordersSum: { __typename: 'Money'; amount: any; currency: Currency | null }
      }
    }>
  } | null
}

export type FlavourProfilesQueryVariables = Exact<{
  language?: InputMaybe<Language>
}>

export type FlavourProfilesQuery = {
  __typename: 'Query'
  flavourProfiles: {
    __typename: 'FlavourProfileCountableConnection'
    edges: Array<{
      __typename: 'FlavourProfileCountableEdge'
      node: { __typename: 'FlavourProfile'; id: string; name: string; image: string }
    }>
  } | null
}

export type InventoryItemsQueryVariables = Exact<{
  filter?: InputMaybe<InventoryItemFilterInput>
  sortBy?: InputMaybe<InventoryItemSortingInput>
  language: Language
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type InventoryItemsQuery = {
  __typename: 'Query'
  inventoryItems: {
    __typename: 'InventoryItemCountableConnection'
    totalCount: number | null
    edges: Array<{
      __typename: 'InventoryItemCountableEdge'
      cursor: string
      node: {
        __typename: 'InventoryItem'
        id: string
        sku: string
        price: {
          __typename: 'TaxedMoney'
          currency: Currency
          gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          net: { __typename: 'Money'; amount: any; currency: Currency | null }
          tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        } | null
        taxGroup: { __typename: 'TaxGroup'; id: string; name: string; rate: any; code: string | null } | null
        product: {
          __typename: 'Product'
          name: string
          id: string
          bodyHtml: string
          manufacturer: { __typename: 'Manufacturer'; id: string; name: string }
          weight: { __typename: 'Weight'; unit: WeightUnitEnum; value: number }
          categories: Array<{ __typename: 'Category'; id: string; slug: string; name: string }>
        }
        productDraftVariant: {
          __typename: 'ProductDraftVariant'
          id: string
          name: string
          slug: string
          description: string
          inventoryItem: { __typename: 'InventoryItem'; id: string } | null
          weight: { __typename: 'Weight'; unit: WeightUnitEnum; value: number } | null
          images: Array<{ __typename: 'ProductImage'; url: string; id: string }>
          productDraft: {
            __typename: 'ProductDraft'
            id: string
            name: string
            slug: string
            description: string
            family: { __typename: 'ProductFamily'; id: string; name: string }
            manufacturer: { __typename: 'Manufacturer'; id: string; name: string }
            weight: { __typename: 'Weight'; unit: WeightUnitEnum; value: number } | null
            images: Array<{ __typename: 'ProductImage'; url: string; id: string }>
            categories: Array<{ __typename: 'Category'; id: string; slug: string; name: string }>
            variants: Array<{
              __typename: 'ProductDraftVariant'
              id: string
              name: string
              slug: string
              inventoryItem: { __typename: 'InventoryItem'; id: string; sku: string } | null
              images: Array<{ __typename: 'ProductImage'; url: string; id: string }>
            }>
          }
        } | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  } | null
}

export type InventoryItemsWithIntegrationsQueryVariables = Exact<{
  first: Scalars['Int']
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<InventoryItemFilterInput>
  integrations?: InputMaybe<Array<InputMaybe<IntegrationCode>> | InputMaybe<IntegrationCode>>
  language: Language
}>

export type InventoryItemsWithIntegrationsQuery = {
  __typename: 'Query'
  inventoryItems: {
    __typename: 'InventoryItemCountableConnection'
    totalCount: number | null
    edges: Array<{
      __typename: 'InventoryItemCountableEdge'
      node: {
        __typename: 'InventoryItem'
        id: string
        sku: string
        integrationMeta: Array<{ __typename: 'InventoryItemIntegrationMeta'; metadata: any | null } | null>
        product: { __typename: 'Product'; id: string; name: string }
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  } | null
}

export type InventoryItemsPreviewQueryVariables = Exact<{
  first: Scalars['Int']
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<InventoryItemFilterInput>
  language: Language
  locationId: Scalars['ID']
  sortBy?: InputMaybe<InventoryItemSortingInput>
}>

export type InventoryItemsPreviewQuery = {
  __typename: 'Query'
  inventoryItems: {
    __typename: 'InventoryItemCountableConnection'
    totalCount: number | null
    edges: Array<{
      __typename: 'InventoryItemCountableEdge'
      cursor: string
      node: {
        __typename: 'InventoryItem'
        id: string
        sku: string
        quantity: number
        price: {
          __typename: 'TaxedMoney'
          currency: Currency
          gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          net: { __typename: 'Money'; amount: any; currency: Currency | null }
          tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        } | null
        product: {
          __typename: 'Product'
          id: string
          name: string
          slug: string
          media: Array<{ __typename: 'Media'; mediaType: MediaType; url: string } | null>
          categories: Array<{ __typename: 'Category'; id: string; name: string; slug: string }>
        }
        productDraftVariant: {
          __typename: 'ProductDraftVariant'
          id: string
          name: string
          slug: string
          images: Array<{ __typename: 'ProductImage'; id: string; url: string }>
          productDraft: {
            __typename: 'ProductDraft'
            id: string
            name: string
            slug: string
            categories: Array<{ __typename: 'Category'; id: string; name: string; slug: string }>
            images: Array<{ __typename: 'ProductImage'; id: string; url: string }>
            variants: Array<{ __typename: 'ProductDraftVariant'; id: string }>
          }
        } | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  } | null
}

export type InventoryLocationsQueryVariables = Exact<{
  filter?: InputMaybe<InventoryLocationFilterInput>
  sortBy?: InputMaybe<InventoryLocationSortingInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type InventoryLocationsQuery = {
  __typename: 'Query'
  inventoryLocations: {
    __typename: 'InventoryLocationCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'InventoryLocationCountableEdge'
      cursor: string
      node: {
        __typename: 'InventoryLocation'
        id: string
        code: string
        name: string
        availabilityType: AvailabilityType
        createdAt: any
        updatedAt: any
        fulfillmentTime: Array<{
          __typename: 'FulfillmentTime'
          id: string
          value: number
          fulfillmentTimeType: FulfillmentTimeType
        }>
      }
    }>
  } | null
}

export type LoginPageQueryVariables = Exact<{
  endpointSlug: Scalars['String']
  pageType?: InputMaybe<PageType>
}>

export type LoginPageQuery = {
  __typename: 'Query'
  page: {
    __typename: 'Page'
    endpointSlug: string
    storeSlug: string
    images: Array<{
      __typename: 'CustomPageImage'
      customIdentifier: string | null
      imagePurpose: ImagePurpose
      image: { __typename: 'Image'; url: string }
    }>
    texts: Array<{ __typename: 'CustomPageText'; text: string; customIdentifier: string | null }>
  } | null
}

export type ManufacturersQueryVariables = Exact<{
  filter?: InputMaybe<ManufacturerFilterInput>
  sortBy?: InputMaybe<ManufacturerSortingInput>
  language?: InputMaybe<Language>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type ManufacturersQuery = {
  __typename: 'Query'
  manufacturers: {
    __typename: 'ManufacturerCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'ManufacturerCountableEdge'
      cursor: string
      node: { __typename: 'Manufacturer'; id: string; name: string }
    }>
  } | null
}

export type NavigationStoresQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  filter?: InputMaybe<StoreFilterInput>
}>

export type NavigationStoresQuery = {
  __typename: 'Query'
  stores: {
    __typename: 'StoreCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'StoreCountableEdge'
      cursor: string
      node: { __typename: 'Store'; id: string; name: string; logo: { __typename: 'Image'; url: string } | null }
    }>
  } | null
}

export type OwnStoresQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type OwnStoresQuery = {
  __typename: 'Query'
  ownStores: {
    __typename: 'StoreCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'StoreCountableEdge'
      cursor: string
      node: { __typename: 'Store'; id: string; name: string; logo: { __typename: 'Image'; url: string } | null }
    }>
  } | null
}

export type PreviewCustomerOrdersQueryVariables = Exact<{
  filter?: InputMaybe<CustomerOrderFilterInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<OrderSortingInput>
}>

export type PreviewCustomerOrdersQuery = {
  __typename: 'Query'
  customerOrders: {
    __typename: 'OrderCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'OrderCountableEdge'
      cursor: string
      node: {
        __typename: 'Order'
        id: string
        number: string
        status: OrderStatus
        createdAt: any
        fulfillmentStatus: FulfillmentStatus
        paymentStatus: OrderPaymentStatus
        total: {
          __typename: 'TaxedMoney'
          currency: Currency
          gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          net: { __typename: 'Money'; amount: any; currency: Currency | null }
          tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        }
        store: { __typename: 'Store'; id: string; name: string }
      }
    }>
  } | null
}

export type PreviewProductDraftsQueryVariables = Exact<{
  filter?: InputMaybe<ProductDraftFilterInput>
  language?: InputMaybe<Language>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<ProductDraftSortingInput>
}>

export type PreviewProductDraftsQuery = {
  __typename: 'Query'
  productDrafts: {
    __typename: 'ProductDraftCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'ProductDraftCountableEdge'
      cursor: string
      node: {
        __typename: 'ProductDraft'
        id: string
        name: string
        slug: string
        family: { __typename: 'ProductFamily'; id: string; name: string; slug: string }
        attributes: Array<{
          __typename: 'ProductAttributeValue'
          id: string
          name: string
          slug: string
          dataType: AttributeType
          value: string | null
        }>
        images: Array<{ __typename: 'ProductImage'; url: string; id: string }>
        variants: Array<{
          __typename: 'ProductDraftVariant'
          id: string
          name: string
          slug: string
          attributes: Array<{
            __typename: 'ProductAttributeValue'
            id: string
            name: string
            slug: string
            dataType: AttributeType
            value: string | null
          }>
        }>
        categories: Array<{ __typename: 'Category'; id: string; slug: string; name: string }>
      }
    }>
  } | null
}

export type PreviewStoreOrdersQueryVariables = Exact<{
  filter?: InputMaybe<VendorOrderFilterInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<OrderSortingInput>
}>

export type PreviewStoreOrdersQuery = {
  __typename: 'Query'
  storeOrders: {
    __typename: 'OrderCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'OrderCountableEdge'
      cursor: string
      node: {
        __typename: 'Order'
        id: string
        number: string
        status: OrderStatus
        createdAt: any
        paymentStatus: OrderPaymentStatus
        fulfillmentStatus: FulfillmentStatus
        total: {
          __typename: 'TaxedMoney'
          currency: Currency
          gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          net: { __typename: 'Money'; amount: any; currency: Currency | null }
          tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        }
        customer: { __typename: 'ContactInformation'; fullName: string } | null
        store: { __typename: 'Store'; id: string; name: string }
      }
    }>
  } | null
}

export type PrivateProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductFilterInput>
  language: Language
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  marketplaceOnly?: InputMaybe<Scalars['Boolean']>
  storeId?: InputMaybe<Scalars['ID']>
}>

export type PrivateProductsQuery = {
  __typename: 'Query'
  privateProducts: {
    __typename: 'ProductCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'ProductCountableEdge'
      cursor: string
      node: {
        __typename: 'Product'
        id: string
        slug: string
        name: string
        bodyHtml: string
        subscribed: boolean
        manufacturer: { __typename: 'Manufacturer'; id: string; name: string }
        offers: Array<{
          __typename: 'ProductOffer'
          sku: string
          discountRate: any
          taxRate: any | null
          quantity: number
          store: {
            __typename: 'Store'
            id: string
            name: string
            legalName: string
            includeTaxesInPrices: boolean
            logo: { __typename: 'Image'; url: string } | null
            categories: Array<{
              __typename: 'Category'
              id: string
              slug: string
              name: string
              path: string
              icon: CategoryIcon | null
            }>
          }
          catalogPrice: { __typename: 'Money'; amount: any; currency: Currency | null }
          sellingPrice: {
            __typename: 'TaxedMoney'
            currency: Currency
            gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
            net: { __typename: 'Money'; amount: any; currency: Currency | null }
            tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          }
          availability: Array<{
            __typename: 'ProductAvailability'
            quantity: number
            leadTimeDays: number
            availabilityType: AvailabilityType
            fulfillmentTime: Array<{
              __typename: 'FulfillmentTime'
              id: string
              value: number
              fulfillmentTimeType: FulfillmentTimeType
            }>
          }>
          defaultShippingMethod: {
            __typename: 'StoreShippingMethod'
            id: string
            expectedShippingTime: number
            shipTodayByHour: number
            timezone: string
            expectedShippingDate: any
            expectedDeliveryDate: any
          } | null
        }>
        weight: { __typename: 'Weight'; unit: WeightUnitEnum; value: number }
        media: Array<{ __typename: 'Media'; mediaType: MediaType; url: string } | null>
        relatedProducts: Array<{
          __typename: 'RelatedProductType'
          type: string
          variants: Array<{
            __typename: 'RelatedProduct'
            description: string
            current: boolean
            value: string
            product: { __typename: 'Product'; id: string; slug: string; name: string }
          }>
        } | null>
        coffeeInfo: {
          __typename: 'CoffeeInfo'
          format: CoffeeFormat | null
          roastProfile: RoastProfile | null
          brewingProfile: BrewingProfile | null
          origins: Array<{
            __typename: 'CoffeeOriginInfo'
            country: Country | null
            processingType: ProcessingType | null
          }>
        } | null
      }
    }>
  } | null
}

export type PrivateProductsListQueryVariables = Exact<{
  filter?: InputMaybe<ProductFilterInput>
  language: Language
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  marketplaceOnly?: InputMaybe<Scalars['Boolean']>
  storeId?: InputMaybe<Scalars['ID']>
}>

export type PrivateProductsListQuery = {
  __typename: 'Query'
  privateProducts: {
    __typename: 'ProductCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'ProductCountableEdge'
      cursor: string
      node: {
        __typename: 'Product'
        id: string
        slug: string
        name: string
        offers: Array<{
          __typename: 'ProductOffer'
          taxRate: any | null
          discountRate: any
          quantity: number
          catalogPrice: { __typename: 'Money'; amount: any; currency: Currency | null }
          sellingPrice: {
            __typename: 'TaxedMoney'
            currency: Currency
            gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
            net: { __typename: 'Money'; amount: any; currency: Currency | null }
            tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          }
          availability: Array<{
            __typename: 'ProductAvailability'
            quantity: number
            leadTimeDays: number
            availabilityType: AvailabilityType
            fulfillmentTime: Array<{
              __typename: 'FulfillmentTime'
              id: string
              value: number
              fulfillmentTimeType: FulfillmentTimeType
            }>
          }>
        }>
        media: Array<{ __typename: 'Media'; mediaType: MediaType; url: string } | null>
      }
    }>
  } | null
}

export type ProductFamiliesQueryVariables = Exact<{
  language: Language
  first: Scalars['Int']
  filter?: InputMaybe<ProductFamilyFilterInput>
}>

export type ProductFamiliesQuery = {
  __typename: 'Query'
  productFamilies: {
    __typename: 'ProductFamilyCountableConnection'
    totalCount: number | null
    edges: Array<{
      __typename: 'ProductFamilyCountableEdge'
      node: { __typename: 'ProductFamily'; id: string; name: string }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  } | null
}

export type ProductListQueryVariables = Exact<{
  filter?: InputMaybe<ProductFilterInput>
  language: Language
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  marketplaceOnly?: InputMaybe<Scalars['Boolean']>
  storeId?: InputMaybe<Scalars['ID']>
}>

export type ProductListQuery = {
  __typename: 'Query'
  products: {
    __typename: 'ProductCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'ProductCountableEdge'
      cursor: string
      node: {
        __typename: 'Product'
        id: string
        slug: string
        name: string
        offers: Array<{
          __typename: 'ProductOffer'
          taxRate: any | null
          discountRate: any
          quantity: number
          catalogPrice: { __typename: 'Money'; amount: any; currency: Currency | null }
          sellingPrice: {
            __typename: 'TaxedMoney'
            currency: Currency
            gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
            net: { __typename: 'Money'; amount: any; currency: Currency | null }
            tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          }
          availability: Array<{
            __typename: 'ProductAvailability'
            quantity: number
            leadTimeDays: number
            availabilityType: AvailabilityType
            fulfillmentTime: Array<{
              __typename: 'FulfillmentTime'
              id: string
              value: number
              fulfillmentTimeType: FulfillmentTimeType
            }>
          }>
        }>
        media: Array<{ __typename: 'Media'; mediaType: MediaType; url: string } | null>
      }
    }>
  } | null
}

export type ProductsQueryVariables = Exact<{
  filter?: InputMaybe<ProductFilterInput>
  language: Language
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  marketplaceOnly?: InputMaybe<Scalars['Boolean']>
  storeId?: InputMaybe<Scalars['ID']>
}>

export type ProductsQuery = {
  __typename: 'Query'
  products: {
    __typename: 'ProductCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'ProductCountableEdge'
      cursor: string
      node: {
        __typename: 'Product'
        id: string
        slug: string
        name: string
        bodyHtml: string
        subscribed: boolean
        manufacturer: { __typename: 'Manufacturer'; id: string; name: string }
        offers: Array<{
          __typename: 'ProductOffer'
          sku: string
          discountRate: any
          taxRate: any | null
          quantity: number
          store: {
            __typename: 'Store'
            id: string
            name: string
            legalName: string
            includeTaxesInPrices: boolean
            logo: { __typename: 'Image'; url: string } | null
            categories: Array<{
              __typename: 'Category'
              id: string
              slug: string
              name: string
              path: string
              icon: CategoryIcon | null
            }>
          }
          catalogPrice: { __typename: 'Money'; amount: any; currency: Currency | null }
          sellingPrice: {
            __typename: 'TaxedMoney'
            currency: Currency
            gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
            net: { __typename: 'Money'; amount: any; currency: Currency | null }
            tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          }
          availability: Array<{
            __typename: 'ProductAvailability'
            quantity: number
            leadTimeDays: number
            availabilityType: AvailabilityType
            fulfillmentTime: Array<{
              __typename: 'FulfillmentTime'
              id: string
              value: number
              fulfillmentTimeType: FulfillmentTimeType
            }>
          }>
          defaultShippingMethod: {
            __typename: 'StoreShippingMethod'
            id: string
            expectedShippingTime: number
            shipTodayByHour: number
            timezone: string
            expectedShippingDate: any
            expectedDeliveryDate: any
          } | null
        }>
        weight: { __typename: 'Weight'; unit: WeightUnitEnum; value: number }
        media: Array<{ __typename: 'Media'; mediaType: MediaType; url: string } | null>
        relatedProducts: Array<{
          __typename: 'RelatedProductType'
          type: string
          variants: Array<{
            __typename: 'RelatedProduct'
            description: string
            current: boolean
            value: string
            product: { __typename: 'Product'; id: string; slug: string; name: string }
          }>
        } | null>
        coffeeInfo: {
          __typename: 'CoffeeInfo'
          format: CoffeeFormat | null
          roastProfile: RoastProfile | null
          brewingProfile: BrewingProfile | null
          origins: Array<{
            __typename: 'CoffeeOriginInfo'
            country: Country | null
            processingType: ProcessingType | null
          }>
        } | null
      }
    }>
  } | null
}

export type QuickbooksConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type QuickbooksConfigurationQuery = {
  __typename: 'Query'
  quickbooksConfiguration: {
    __typename: 'QuickbooksConfiguration'
    id: string
    integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
  } | null
}

export type SendcloudConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type SendcloudConfigurationQuery = {
  __typename: 'Query'
  sendcloudConfiguration: {
    __typename: 'SendcloudConfiguration'
    id: string
    publicKey: string
    secretKey: string
    integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
  } | null
}

export type ShipStationConfigurationQueryVariables = Exact<{ [key: string]: never }>

export type ShipStationConfigurationQuery = {
  __typename: 'Query'
  shipstationConfiguration: {
    __typename: 'ShipStationConfiguration'
    id: string
    apiKey: string
    apiSecret: string
    integration: { __typename: 'Integration'; id: string; code: IntegrationCode; active: boolean }
  } | null
}

export type StoreCategoriesQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  filter?: InputMaybe<StoreFilterInput>
  language: Language
}>

export type StoreCategoriesQuery = {
  __typename: 'Query'
  stores: {
    __typename: 'StoreCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'StoreCountableEdge'
      cursor: string
      node: {
        __typename: 'Store'
        categories: Array<{
          __typename: 'Category'
          id: string
          slug: string
          name: string
          path: string
          icon: CategoryIcon | null
        }>
      }
    }>
  } | null
}

export type StoreOrderQueryVariables = Exact<{
  filter?: InputMaybe<VendorOrderFilterInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<OrderSortingInput>
}>

export type StoreOrderQuery = {
  __typename: 'Query'
  storeOrders: {
    __typename: 'OrderCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'OrderCountableEdge'
      cursor: string
      node: {
        __typename: 'Order'
        id: string
        number: string
        title: string
        status: OrderStatus
        createdAt: any
        updatedAt: any
        fulfillmentStatus: FulfillmentStatus
        paymentStatus: OrderPaymentStatus
        notes: string | null
        shippingMethod: ShippingMethod
        deliveryDate: any | null
        total: {
          __typename: 'TaxedMoney'
          currency: Currency
          gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          net: { __typename: 'Money'; amount: any; currency: Currency | null }
          tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        }
        store: {
          __typename: 'Store'
          id: string
          name: string
          legalName: string
          logo: { __typename: 'Image'; url: string } | null
        }
        customer: {
          __typename: 'ContactInformation'
          email: string
          firstName: string
          lastName: string
          fullName: string
          phone: string | null
        } | null
        shippingAddress: {
          __typename: 'ShippingAddress'
          street: string
          houseNumber: string
          city: string
          zipCode: string
          name: string
          country: string
          companyName: string | null
          email: string | null
          phone: string | null
        } | null
        billingAddress: {
          __typename: 'BillingAddress'
          street: string
          houseNumber: string
          city: string
          zipCode: string
          name: string
          country: string
          companyName: string | null
          taxId: string | null
        } | null
        paymentMethod: {
          __typename: 'PaymentMethod'
          id: string
          gateway: string
          paymentType: PaymentType
          payer: {
            __typename: 'Payer'
            id: string
            user: {
              __typename: 'User'
              id: string
              email: string
              phone: string | null
              firstName: string
              lastName: string
              fullName: string
            } | null
          }
        } | null
        payments: Array<{
          __typename: 'Payment'
          id: string
          pspReference: string | null
          abandoned: boolean
          status: PaymentStatus
          gateway: string
          createdAt: any
          updatedAt: any
          paymentMethod: {
            __typename: 'PaymentMethod'
            id: string
            gateway: string
            paymentType: PaymentType
            payer: {
              __typename: 'Payer'
              id: string
              user: {
                __typename: 'User'
                id: string
                email: string
                phone: string | null
                firstName: string
                lastName: string
                fullName: string
              } | null
            }
          } | null
          payer: {
            __typename: 'Payer'
            id: string
            user: {
              __typename: 'User'
              id: string
              email: string
              phone: string | null
              firstName: string
              lastName: string
              fullName: string
            } | null
          } | null
          total: { __typename: 'Money'; amount: any; currency: Currency | null }
          capturedAmount: { __typename: 'Money'; amount: any; currency: Currency | null }
          transactions: Array<{
            __typename: 'PaymentTransaction'
            id: string
            token: string | null
            kind: TransactionKind
            isSuccess: boolean
            total: { __typename: 'Money'; amount: any; currency: Currency | null }
          } | null>
        }>
        activities: Array<{
          __typename: 'Activity'
          id: string
          event: ActivityEvent
          createdAt: any
          updatedAt: any
          mergedMetadata: any | null
          actor: { __typename: 'ContactInformation'; fullName: string; email: string; phone: string | null } | null
        } | null>
        invoices: Array<{
          __typename: 'Invoice'
          id: string
          number: string
          createdAt: any
          effectiveDate: any | null
          pdfFile: { __typename: 'PDF'; url: string }
          total: { __typename: 'Money'; amount: any; currency: Currency | null } | null
        }>
        items: Array<{
          __typename: 'OrderItem'
          id: string
          productName: string | null
          sku: string | null
          quantity: number
          type: OrderItemType
          total: {
            __typename: 'TaxedMoney'
            currency: Currency
            gross: { __typename: 'Money'; amount: any; currency: Currency | null } | null
            net: { __typename: 'Money'; amount: any; currency: Currency | null }
            tax: { __typename: 'Money'; amount: any; currency: Currency | null } | null
          }
        }>
      }
    }>
  } | null
}

export type StoresQueryVariables = Exact<{
  filter?: InputMaybe<StoreFilterInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
  language: Language
}>

export type StoresQuery = {
  __typename: 'Query'
  stores: {
    __typename: 'StoreCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
    edges: Array<{
      __typename: 'StoreCountableEdge'
      cursor: string
      node: {
        __typename: 'Store'
        id: string
        name: string
        legalName: string
        includeTaxesInPrices: boolean
        logo: { __typename: 'Image'; url: string } | null
        categories: Array<{
          __typename: 'Category'
          id: string
          slug: string
          name: string
          path: string
          icon: CategoryIcon | null
        }>
      }
    }>
  } | null
}

export type TaxGroupsQueryVariables = Exact<{
  filter?: InputMaybe<TaxGroupFilterInput>
  sortBy?: InputMaybe<TaxGroupSortingInput>
  before?: InputMaybe<Scalars['String']>
  after?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}>

export type TaxGroupsQuery = {
  __typename: 'Query'
  taxGroups: {
    __typename: 'TaxGroupCountableConnection'
    totalCount: number | null
    pageInfo: {
      __typename: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
    edges: Array<{
      __typename: 'TaxGroupCountableEdge'
      cursor: string
      node: { __typename: 'TaxGroup'; id: string; name: string; rate: any; code: string | null }
    }>
  } | null
}
